import { Column } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { ArrowDown, ArrowDownUp, ArrowUp, Eye } from 'lucide-react'
import { Link } from 'react-router-dom'
import { paths } from 'src/routes/paths'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from 'src/shared/components/ui/drawer'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'
import { cn } from 'src/shared/components/ui/utils'

import { Receipt } from '../components/Receipt'
import { Application } from './consts'

const SortableHeader = ({
  column,
  children,
}: {
  column: Column<Application, unknown>
  children: React.ReactNode
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Row
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          className="cursor-pointer items-center"
        >
          {children}
          {column.getIsSorted() === 'asc' ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : column.getIsSorted() === 'desc' ? (
            <ArrowDown className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDownUp className="ml-2 h-4 w-4" />
          )}
        </Row>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
          Sort Ascending
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
          Sort Descending
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const Text = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => <p className={cn('text-xs', className)}>{children}</p>

export const useColumns = () => {
  return [
    {
      accessorKey: 'app',
      header: ({ column }) => (
        <SortableHeader column={column}>Sender</SortableHeader>
      ),
      cell: ({ row }) => (
        <Row className="justify-between">
          <Link
            className="cursor-pointer flex items-center gap-2 my-1"
            to={`${paths.software_table}/${row.original.app.id}`}
          >
            <SaasIcon
              toolName={row.original.app.name}
              src={row.original.app.logo_url || ''}
              size="sm"
            />
            <Text>{row.original.app.name}</Text>
          </Link>
        </Row>
      ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.app.name.localeCompare(rowB.original.app.name)
      },
      accessorFn: (row) => row.app.name,
      size: 200,
    },
    {
      accessorKey: 'document_type',
      header: ({ column }) => (
        <SortableHeader column={column}>Type</SortableHeader>
      ),
      cell: ({ row }) =>
        row.getValue('document_type') && (
          <Badge className="rounded-full text-[11px] font-normal">
            {row.getValue('document_type')}
          </Badge>
        ),
    },
    {
      accessorKey: 'email_received',
      header: ({ column }) => (
        <SortableHeader column={column}>Email received</SortableHeader>
      ),
      cell: ({ row }) => (
        <Text>
          {dayjs(row.getValue('email_received')).format('MMM DD, YYYY')}
        </Text>
      ),
    },
    {
      accessorKey: 'total_cost',
      header: ({ column }) => (
        <SortableHeader column={column}>Total cost</SortableHeader>
      ),
      cell: ({ row }) => <Text>{row.getValue('total_cost')}</Text>,
    },
    {
      accessorKey: 'due_date',
      header: ({ column }) => (
        <SortableHeader column={column}>Due date</SortableHeader>
      ),
      cell: ({ row }) =>
        row.getValue('due_date') && (
          <Text
            className={cn(
              ' gap-1 items-center',
              dayjs(row.getValue('due_date')).isAfter(new Date()) ||
                dayjs(row.getValue('due_date')).isSame(new Date(), 'day')
                ? 'text-orange-600'
                : 'text-gray-500'
            )}
          >
            {dayjs(row.getValue('due_date')).isAfter(new Date())
              ? `Due in ${dayjs(row.getValue('due_date')).diff(
                  dayjs(),
                  'days'
                )} days`
              : dayjs(row.getValue('due_date')).format('MMM DD, YYYY')}
          </Text>
        ),
    },
    // {
    //   accessorKey: 'warning_info',
    //   header: ({ column }) => (
    //     <SortableHeader column={column}>Info</SortableHeader>
    //   ),
    //   cell: ({ row }) => <Text>{row.getValue('warning_info')}</Text>,
    // },
    {
      accessorKey: 'email_recipient',
      header: ({ column }) => (
        <SortableHeader column={column}>Email recipient</SortableHeader>
      ),
      cell: ({ row }) => <Text>{row.getValue('email_recipient')}</Text>,
    },
    {
      accessorKey: 'action',
      header: ({ column }) => (
        <SortableHeader column={column}>Action</SortableHeader>
      ),
      cell: ({ row }) => (
        <Drawer>
          <DrawerTrigger>
            <Button variant="light" size="tiny" className="m-1">
              <Eye className="w-4 h-4 mr-2" />
              Open
            </Button>
          </DrawerTrigger>

          <DrawerContent>
            <Receipt receipt={row.original.receipt} />
          </DrawerContent>
        </Drawer>
      ),
    },
  ]
}
