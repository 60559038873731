import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ChevronDown, ChevronRight, Info } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useToolsApi } from 'src/api/db/apiTools'
import { useUserActivityApi } from 'src/api/db/apiUserActivity'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Separator, Tooltip } from 'src/shared/components/ui'
import { Progress } from 'src/shared/components/ui/progress'
import { cn } from 'src/shared/components/ui/utils'

import logo from '../../../shared/components/Paywall/logo.png'
import { OrgDialog } from './components/OrgDialog'
import { useLinks } from './consts'
import {
  Sidebar,
  SidebarBody,
  SidebarLink,
  SidebarLinkChildren,
} from './sidebar'

dayjs.extend(relativeTime)

const TodoItem = ({
  title,
  description,
  isDone,
}: {
  title: string
  description: string
  isDone: boolean
}) => (
  <Row className="items-center gap-2">
    <Tooltip content={<p className="w-64">{description}</p>} delayDuration={0}>
      <Info className="h-4 w-4 text-muted-foreground" />
    </Tooltip>

    <p className={cn('text-xs', isDone ? 'line-through' : '')}>{title}</p>
  </Row>
)

export function SidebarDemo({ children }: { children: React.ReactNode }) {
  const { orgUser } = useOrgUsersApi()
  const [expandedLink, setExpandedLink] = useState<number | null>(null)
  const location = useLocation()
  const links = useLinks()
  const navigate = useNavigate()

  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tools_ = useMemo(() => tools, [tools])
  const { userActivitiesAll } = useUserActivityApi({ org_id: orgId || '' })

  const toggleExpand = (idx: number) => {
    setExpandedLink(expandedLink === idx ? null : idx)
  }

  const totalYearlyWaste =
    tools_?.calculated.totalAnnualWasteLowUsage ||
    tools_?.calculated?.totalAnnualWasteSeatUtilization ||
    0

  const trackUsageWithExtensionDone = (userActivitiesAll?.data?.length || 0) > 0
  const trackCostFromSourceDone = (tools_?.calculated.annualSpend || 0) > 0
  const addLicencesToListDone = (tools_?.tools?.length || 0) > 0
  const seeSavingsDone = true

  const getStartedDone = [
    trackUsageWithExtensionDone,
    trackCostFromSourceDone,
    addLicencesToListDone,
    seeSavingsDone,
  ].filter(Boolean).length

  return (
    <>
      <div
        // This wraps the whole app
        className={cn(
          'flex flex-col md:flex-row w-full flex-1 mx-auto overflow-hidden',
          'h-screen',
          'bg-theme_light'
        )}
      >
        <Sidebar>
          <SidebarBody className="justify-between gap-10">
            <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
              <OrgDialog avatar={logo} orgUser={orgUser} />
              <Separator className="my-2 bg-black/10" />

              <Button
                variant="golden"
                className="rounded-full"
                onClick={() =>
                  navigate(paths.software_insights + '?tab=cost-reduction')
                }
              >
                ${totalYearlyWaste} USD
              </Button>

              <p className="text-xs text-neutral-500 text-center mt-1">
                Potential yearly savings
              </p>
              <Separator className="my-2 bg-black/10" />

              <div className="mt-2 flex flex-col gap-2">
                {links.map((link, idx) => {
                  const isActive = location.pathname === link.href
                  // ||
                  // (location.pathname.includes('software') &&
                  //   link.href.includes('software'))

                  return (
                    <div key={idx} id={link.id}>
                      <div
                        onClick={() => {
                          link?.children && toggleExpand(idx)
                          navigate(link.href)
                        }}
                        className={cn(
                          'cursor-pointer flex items-center border border-transparent p-1 rounded-lg  pl-3 transition-all duration-50 active:scale-95',
                          isActive ? 'bg-white/60 border border-black/20' : ''
                        )}
                      >
                        <SidebarLink link={link} isActive={isActive} />

                        {link?.children &&
                          (expandedLink === idx ? (
                            <ChevronDown className="h-4 w-4 ml-auto mr-2" />
                          ) : (
                            <ChevronRight className="h-4 w-4 ml-auto mr-2" />
                          ))}
                      </div>

                      {link?.children && expandedLink === idx && (
                        <div className="ml-4 mt-2 flex flex-col gap-2">
                          {link?.children.map((childLink, childIdx) => (
                            <div key={childIdx}>
                              <SidebarLinkChildren link={childLink} />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>

            {/* {!onboarded && user?.data?.email && <OnboardingWidgetNew />} */}

            {/* {organization?.data?.stripe_status === 'not_paid' &&
              (organization?.data?.scanned_emails || 0) > 19 && <Paywall />}

            <div>
              {organization?.data?.stripe_status === 'not_paid' ? (
                <BuyNow />
              ) : (
                <Badge
                  className="py-1 px-2 rounded-full cursor-pointer"
                  onClick={() => navigate(paths.organization + '?tab=billing')}
                >
                  <Row className="items-center">
                    <Mail className="h-3 w-3 text-yellow-500 mr-1" />
                    {organization?.data?.scanned_emails}
                    <UsersRound className="ml-2 h-3 w-3 text-yellow-500 mr-1" />
                    {orgUsers?.data?.length}
                  </Row>
                </Badge>
              )}
            </div> */}

            {/*
             * Sending an email when scan is complete
             * Implementing onboarding todo
             */}

            {getStartedDone !== 4 && (
              <Column className="gap-2 mb-2">
                <p className="text-sm text-foreground-muted">
                  Getting started ({getStartedDone}/4)
                </p>
                <Progress
                  value={getStartedDone * 25}
                  className="w-full h-2 bg-white"
                  indicatorColor="bg-red-500"
                />

                <TodoItem
                  title="Track usage with extension"
                  description="After your team has installed the extension, wait about 15 minutes for the data to appear. See activities in Usage → Browser extension → Activity"
                  isDone={trackUsageWithExtensionDone}
                />

                <TodoItem
                  title="Track cost from a source"
                  description="You will add licences that has been detected from your receipts & invoices, and from the extension."
                  isDone={trackCostFromSourceDone}
                />

                <TodoItem
                  title="Add licences to list"
                  description="You will see the savings in the software insights tab."
                  isDone={addLicencesToListDone}
                />

                <TodoItem
                  title="See savings"
                  description="You will see the savings in the software insights tab, or in the top of this sidebar."
                  isDone={Boolean(seeSavingsDone)}
                />
              </Column>
            )}
          </SidebarBody>
        </Sidebar>

        {/* The main layout container */}
        <div className="flex h-full flex-col flex-1 py-8 p-10 overflow-y-auto">
          {children}
        </div>
      </div>
    </>
  )
}
