import LogRocket from 'logrocket'
import { Loader } from 'lucide-react'
import posthog from 'posthog-js'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useOrgsApi } from 'src/api/db/apiOrgs'
import { Roles } from 'src/shared/consts'

import { useAuth } from '../../shared/hooks/authProvider'
import { paths } from '../paths'

export const ProtectedRoute = ({ component, ...args }: any) => {
  const { loading, session } = useAuth()
  const navigate = useNavigate()
  const { userAllOrgs } = useOrgUsersApi()
  const { orgId } = useOrgIdApi()
  const { organization } = useOrgsApi({ orgId })
  const path = useLocation()

  // Redirect to onboarding if organization exists but hasn't completed onboarding
  useEffect(() => {
    if (
      !path.pathname.includes('onboarding-done') &&
      organization?.status === 200 &&
      !organization?.data?.onboarded
    ) {
      navigate(paths.onboarding + '/1')
    }
  }, [organization?.data, organization?.status, navigate])

  useEffect(() => {
    const userId = session?.user?.id

    // If user is not logged in, redirect to login page
    if (!loading && !userId) navigate(paths.loginSignUp)

    // If user is only extension user, redirect to extension page
    if (userAllOrgs?.data) {
      const isAdminOrSuperadmin = userAllOrgs?.data?.some(({ role_id }) =>
        [Roles.ADMIN, Roles.SUPERADMIN].includes(role_id)
      )
      const isOnlyExtensionUser = !isAdminOrSuperadmin
      if (isOnlyExtensionUser) navigate(paths.extension)
    }

    if (userId && process.env.NODE_ENV === 'production') {
      // If user is logged in, identify them in LogRocket
      LogRocket.identify(userId, {
        email: session?.user?.email || '',
      })

      posthog.identify(userId, {
        email: session?.user?.email || '',
        organization: organization?.data?.name || '',
      })
    }
  }, [session?.user?.id, userAllOrgs?.data])

  if (organization?.status !== 200) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader className="animate-spin" />
      </div>
    )
  }

  const Component = component
  return <Component {...args} />
}
