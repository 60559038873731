import { Column, Row } from 'src/shared/components/Semantic/all'
import { Image } from 'src/shared/components/ui'
import { Card } from 'src/shared/components/ui/card'

export const Card1 = ({
  title,
  description,
  icon,
  selected,
  onClick,
  cardId,
  disabled = false,
}: {
  title: string
  description: string
  icon: string
  selected: string
  onClick: () => void
  cardId: string
  disabled?: boolean
}) => (
  <Card
    className={`w-full cursor-pointer transition-all hover:shadow-md ${
      selected === cardId ? 'shadow-md border-primary' : ''
    } ${disabled ? 'opacity-50' : ''}`}
    onClick={onClick}
  >
    <Column className="gap-4">
      <Row className="gap-4 items-center">
        <div className="flex h-12 w-12 p-2 items-center justify-center rounded-full bg-primary/10">
          <Image src={icon} alt="gmail" className="w-full" />
        </div>

        <Column>
          <h3>{title}</h3>

          <p className="text-sm text-muted-foreground">{description}</p>
        </Column>
      </Row>
    </Column>
  </Card>
)
