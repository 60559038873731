import { Column } from '@tanstack/react-table'
import {
  Activity,
  ArrowDown,
  ArrowDownUp,
  ArrowUp,
  CreditCard,
  Pencil,
  Trash2,
  TriangleAlert,
} from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgVendors } from 'src/api/db/apiOrgVendors'
import { useToolsApi } from 'src/api/db/apiTools'
import { paths } from 'src/routes/paths'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Row } from 'src/shared/components/Semantic/all'
import { Tooltip } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'

import { Application } from './consts'

// const TypeMap = {
//   software: (
//     <Row className="gap-2 items-center">
//       <Laptop className="w-4 h-4" /> Software
//     </Row>
//   ),
//   service: (
//     <Row className="gap-2 items-center">
//       <HandHelping className="w-4 h-4" /> Service
//     </Row>
//   ),
//   other: (
//     <Row className="gap-2 items-center">
//       <Code className="w-4 h-4" /> Other
//     </Row>
//   ),
// }

const SortableHeader = ({
  column,
  children,
}: {
  column: Column<Application, unknown>
  children: React.ReactNode
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Row
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          className="cursor-pointer items-center"
        >
          {children}
          {column.getIsSorted() === 'asc' ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : column.getIsSorted() === 'desc' ? (
            <ArrowDown className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDownUp className="ml-2 h-4 w-4" />
          )}
        </Row>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
          Sort Ascending
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
          Sort Descending
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const useColumns = () => {
  const navigate = useNavigate()

  const { updateOrgVendor } = useOrgVendors()
  const { orgId } = useOrgIdApi()
  const { deleteTool } = useToolsApi({
    organization_id: orgId || '',
  })

  return [
    {
      accessorKey: 'app',
      header: ({ column }) => (
        <SortableHeader column={column}>Licence</SortableHeader>
      ),
      cell: ({ row }) => (
        <Row className="justify-between">
          <Link
            className="cursor-pointer flex items-center gap-2 my-1"
            to={`${paths.software_table}/${row.original.app.id}`}
          >
            <SaasIcon
              toolName={row.original.app.name}
              src={row.original.app.logo_url || ''}
              size="sm"
            />
            {row.original.app.name}
          </Link>

          <Row className="gap-2 text-xs w-fit items-center">
            <Badge className="px-2 py-1">
              <Tooltip
                content={
                  <Row className="text-xs gap-2 items-center">
                    {row.original.tracking_cost && (
                      <div className="h-2 w-2 bg-green-500 rounded-full" />
                    )}

                    {!row.original.tracking_cost && (
                      <TriangleAlert className="h-3 w-3 text-orange-500" />
                    )}

                    {row.original.tracking_cost
                      ? 'Tracking cost'
                      : `Add cost tracking. Go to ${row.original.app.name} → Settings → Cost`}
                  </Row>
                }
                delayDuration={0}
              >
                <CreditCard
                  className={`h-3 w-3 ${
                    row.original.tracking_cost
                      ? 'text-green-500'
                      : 'text-orange-500'
                  }`}
                />
              </Tooltip>
            </Badge>

            <Badge className="px-2 py-1">
              <Tooltip
                content={
                  <Row className="text-xs gap-2 items-center">
                    {row.original.tracking_usage && (
                      <div className="h-2 w-2 bg-green-500 rounded-full" />
                    )}

                    {!row.original.tracking_usage && (
                      <TriangleAlert className="h-3 w-3 text-orange-500" />
                    )}

                    {row.original.tracking_usage
                      ? 'Tracking usage'
                      : `Add usage tracking. Go to ${row.original.app.name} → Settings → Usage`}
                  </Row>
                }
                delayDuration={0}
              >
                <Activity
                  className={`h-3 w-3 ${
                    row.original.tracking_usage
                      ? 'text-green-500'
                      : 'text-orange-500'
                  }`}
                />
              </Tooltip>
            </Badge>
          </Row>
        </Row>
      ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.app.name.localeCompare(rowB.original.app.name)
      },
      accessorFn: (row) => row.app.name,
    },
    {
      accessorKey: 'dept',
      header: ({ column }) => (
        <SortableHeader column={column}>Department</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('dept')}</div>,
    },
    {
      accessorKey: 'owner',
      header: ({ column }) => (
        <SortableHeader column={column}>Owner</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('owner')}</div>,
    },
    {
      accessorKey: 'spendMonthly',
      header: ({ column }) => (
        <SortableHeader column={column}>Monthly spend</SortableHeader>
      ),
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spendMonthly'))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend)
        return <div className="text-right ">{spend ? formatted : ''}</div>
      },
    },
    {
      accessorKey: 'spendAnnual',
      header: ({ column }) => (
        <SortableHeader column={column}>Annual spend</SortableHeader>
      ),
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spendAnnual'))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend)
        return <div className="text-right ">{spend ? formatted : ''}</div>
      },
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <Row className="gap-2 justify-end">
          <button
            className="p-1 hover:bg-gray-100 rounded"
            onClick={() => {
              navigate(
                `${paths.software_table}/${row.original.app.id}/#settings`
              )
            }}
          >
            <Pencil className="h-4 w-4 text-gray-500" />
          </button>

          <button
            className="p-1 hover:bg-gray-100 rounded"
            onClick={async () => {
              await deleteTool
                .mutateAsync({
                  id: row.original.app.id,
                })
                .then(async () => {
                  if (row.original.org_vendor_id) {
                    await updateOrgVendor.mutateAsync({
                      id: row.original.org_vendor_id,
                      status: 'not_in_stack',
                    })
                  }
                })
            }}
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </button>
        </Row>
      ),
    },
  ]
}
