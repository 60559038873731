import { useGoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import {
  ArrowRight,
  Check,
  Lock,
  MailSearchIcon,
  PlusCircle,
  ShieldCheck,
  Trash,
} from 'lucide-react'
import { useState } from 'react'
import { useEmailAccountsApi } from 'src/api/db/apiEmailAccounts'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { queryKeys } from 'src/api/db/queryKeys'
import { useScanEmailAccount } from 'src/api/server/scanEmailAccount'
import { ServerRoutes } from 'src/api/server/serverRoutes'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Image, ScrollArea } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'src/shared/components/ui/breadcrumb'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/shared/components/ui/dialog'
import { useAuth } from 'src/shared/hooks/authProvider'

import img1 from './1.png'
import imag1 from './11.png'
import imag2 from './22.png'
import { DatePickerAccounts } from './DatePickerAccounts'

export const AccountsDialog = ({
  dialogOpen,
  setDialogOpen,
}: {
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
}) => {
  const { session } = useAuth()
  const { emailAccountsFromOrg, deleteEmailAccount } = useEmailAccountsApi({
    email: session?.user?.email,
  })
  const { orgId } = useOrgIdApi()
  const { orgUser } = useOrgUsersApi()
  const scanEmailAccount = useScanEmailAccount()

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [date_from, setDateFrom] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  )
  const [date_to, setDateTo] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  )

  const queryClient = useQueryClient()

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: [
      // // Non sensitive scopes
      // 'https://www.googleapis.com/auth/gmail.labels',
      // // // Sensitive scopes
      // 'https://www.googleapis.com/auth/tasks',
      // 'https://www.googleapis.com/auth/tasks.readonly',
      // // Restricted scopes
      'https://www.googleapis.com/auth/gmail.readonly',
      // 'https://www.googleapis.com/auth/gmail.modify',
    ].join(' '),
    onSuccess: async (codeResponse) => {
      console.log(codeResponse)
      setLoading(true)
      const tokens = await fetch(
        process.env.REACT_APP_BE_SERVER_URL + ServerRoutes.googleAuth,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: codeResponse.code,
            organization_id: orgId,
          }),
        }
      )

      if (tokens) {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.emailAccounts],
        })
        setLoading(false)
        setPage(1)
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  })

  const Breadcrumbs = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => setPage(1)}>Start</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />

        <BreadcrumbItem>
          <BreadcrumbLink
            className={page === 2 ? 'text-blue-500' : ''}
            onClick={() => setPage(2)}
          >
            Permissions
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />

        <BreadcrumbItem>
          <BreadcrumbLink
            className={page === 3 ? 'text-blue-500' : ''}
            onClick={() => setPage(3)}
          >
            Google verification
          </BreadcrumbLink>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )

  const Page1 = () => (
    <>
      <DialogHeader>
        <DialogTitle>Accounts</DialogTitle>
      </DialogHeader>

      {!emailAccountsFromOrg?.data?.length && (
        <p className="text-sm text-muted-foreground mb-2">
          Connect your accounts to scan for receipts in your inbox.
        </p>
      )}

      <Column className="gap-2 mt-2 ">
        {emailAccountsFromOrg?.data?.map((emailAccount) => (
          <Row
            key={emailAccount.id}
            className="justify-between items-center bg-theme_dark p-3 rounded-lg border"
          >
            <Row className="gap-2">
              <DatePickerAccounts
                date={date_from}
                setDate={setDateFrom}
                placeholder="From"
              />

              <DatePickerAccounts
                date={date_to}
                setDate={setDateTo}
                placeholder="To"
              />
            </Row>

            <Row className="gap-2">
              <Column className="gap-1 ml-auto">
                <Button
                  isLoading={scanEmailAccount.isLoading}
                  onClick={() => {
                    scanEmailAccount.mutate({
                      email: emailAccount.email || '',
                      organization_id: orgId,
                      org_user_id: orgUser?.data?.id!,
                      after: date_from ? format(date_from, 'yyyy/M/d') : '',
                      before: date_to ? format(date_to, 'yyyy/M/d') : '',
                    })

                    setDialogOpen(false)
                  }}
                >
                  <MailSearchIcon className="w-4 h-4 mr-2" />
                  {emailAccount.email}
                </Button>

                <p className="text-xs text-muted-foreground ml-2">
                  {emailAccount.last_scanned
                    ? `Scanned ${dayjs(emailAccount.last_scanned).fromNow()}`
                    : 'Not scanned'}
                </p>
              </Column>

              <Button
                isLoading={deleteEmailAccount.isLoading}
                variant="ghost"
                className="text-red-500"
                onClick={() => deleteEmailAccount.mutate(emailAccount.id)}
              >
                <Trash className="w-4 h-4" />
              </Button>
            </Row>
          </Row>
        ))}

        <Row className="gap-2">
          <Button
            isLoading={loading}
            size={'tiny'}
            variant="secondary"
            className="w-fit mt-4 mr-auto"
            onClick={() => {
              setPage(2)
              // googleLogin()
            }}
          >
            <PlusCircle className="w-3 h-3 mr-1" />
            Add Gmail
          </Button>

          <Button
            isLoading={loading}
            size={'tiny'}
            variant="transparent"
            className="w-fit mt-4 ml-auto"
            onClick={() =>
              window.open(
                'https://cautious-elbow-be7.notion.site/PinnOne-Security-Compliance-18f015b4f55480fc84adda046f5826bf?pvs=73',
                '_blank'
              )
            }
          >
            <ShieldCheck className="w-3 h-3 mr-1" />
            About security
          </Button>

          <Button
            isLoading={loading}
            size={'tiny'}
            variant="transparent"
            className="w-fit mt-4"
            onClick={() =>
              window.open('https://myaccount.google.com/connections', '_blank')
            }
          >
            <Lock className="w-3 h-3 mr-1" />
            Your permissions
          </Button>
        </Row>
      </Column>
    </>
  )

  const Page2 = () => (
    <>
      <DialogHeader>
        <Breadcrumbs />
      </DialogHeader>

      <ScrollArea className="h-[500px]">
        <p className="text-l mb-2 text-center mt-[150px]">
          Firstly, what permissions are needed?
        </p>

        <Column className="gap-3 mt-2">
          <Image
            src={img1}
            alt="receipts"
            className="w-[45%] mx-auto rounded-lg"
          />

          <Badge className="w-fit mx-auto bg-green-500/10">
            <ShieldCheck className="w-4 h-4 mr-2 text-green-500" />

            <p className="text-sm font-medium mx-auto text-center text-green-500">
              Needed for scanning receipts from your inbox.
            </p>
          </Badge>
        </Column>

        <Row className="w-full justify-end">
          <Button className="mt-4 mx-auto" onClick={() => setPage(3)}>
            Next
            <ArrowRight className="w-4 h-4 ml-2" />
          </Button>
        </Row>
      </ScrollArea>
    </>
  )

  const Page3 = () => (
    <>
      <DialogHeader>
        <Breadcrumbs />
      </DialogHeader>

      <ScrollArea className="h-[500px] flex flex-col items-center">
        <p className="text-lg text-center mt-4">Google verification</p>

        <Button
          isLoading={loading}
          className="w-fit mt-4 mx-auto"
          onClick={() => {
            googleLogin()
          }}
        >
          <Check className="w-3 h-3 mr-1" />
          Ok, let's go!
        </Button>

        <Column className="gap-2 mt-4">
          <i className="text-sm font-normal mx-auto text-center w-[50%]">
            We are waiting for Google to verify our app.
          </i>

          <Image
            src={imag1}
            alt="gmail"
            className="w-[50%] mx-auto object-cover rounded-lg border"
          />

          <Image
            src={imag2}
            alt="gmail"
            className="w-[50%] mx-auto object-cover rounded-lg border"
          />
        </Column>
      </ScrollArea>
    </>
  )

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogContent>
        {page === 1 && <Page1 />}
        {page === 2 && <Page2 />}
        {page === 3 && <Page3 />}
      </DialogContent>
    </Dialog>
  )
}
