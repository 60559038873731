import {
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { Column } from 'src/shared/components/Semantic/all'
import { Checkbox } from 'src/shared/components/ui/checkbox'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'

import { useColumns } from './columns'

export const ReceiptsTable = ({
  receipts,
  setSelectedRows,
}: {
  receipts: any
  setSelectedRows: (selectedRows: any) => void
}) => {
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const columns = useColumns()
  const receipts_ = React.useMemo(() => receipts, [receipts])

  const tableData = React.useMemo(
    () =>
      receipts_
        ?.map((receipt) => {
          return {
            app: {
              id: receipt.id || '',
              name: receipt?.sender?.name || '',
              // @ts-ignore
              logo_url: receipt.tool?.vendor?.logo_url || '',
            },
            email_received: receipt.email_received || '',
            due_date: receipt.due_date || '',
            warning_info: receipt.warning_info || '',
            email_recipient: receipt.email_recipient || '',
            receipt: receipt,
            total_cost: `${receipt.total_cost} ${receipt.currency}` || '',
            document_type: receipt.document_type || '',
          }
        })
        ?.sort((a, b) => b.email_received!.localeCompare(a.email_received!)) ??
      [],
    [receipts_]
  )

  const selectionColumn = React.useMemo(
    () => ({
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          label=""
          checked={table.getIsAllPageRowsSelected()}
          setChecked={(checked) => {
            table.toggleAllPageRowsSelected(checked)
            setTimeout(() => {
              setSelectedRows(table.getSelectedRowModel().flatRows)
            }, 300)
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          label=""
          checked={row.getIsSelected()}
          setChecked={(checked) => {
            row.toggleSelected(checked)
            setTimeout(() => {
              setSelectedRows(table.getSelectedRowModel().flatRows)
            }, 300)
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    }),
    []
  )

  const allColumns = React.useMemo(
    () => [selectionColumn, ...columns],
    [selectionColumn, columns]
  )

  const table = useReactTable({
    data: tableData,
    columns: allColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
      pagination: {
        pageIndex: 0,
        pageSize: 10000,
      },
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
  })

  return (
    <Column className="gap-4 w-full h-full my-4">
      <div className="w-full">
        <div
          className="border rounded-lg bg-white"
          style={{
            height: 'calc(100vh - 300px)',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <Table>
            <TableHeader className="sticky top-0 z-10 bg-theme_dark">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className="h-10 border-l first:border-l-0 border-b text-xs"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="px-4 py-1 border-l first:border-l-0 border-b"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24">
                    No receipts or invoices found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </Column>
  )
}
