import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ArrowLeft, ArrowRightLeft, CirclePlus, Settings } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useOrgsApi } from 'src/api/db/apiOrgs'
import { useUsersApi } from 'src/api/db/apiUsers'
import { queryKeys } from 'src/api/db/queryKeys'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Input,
  ScrollArea,
} from 'src/shared/components/ui'
import { Avatar } from 'src/shared/components/ui/avatar'
import { Roles } from 'src/shared/consts'
import { useAuth } from 'src/shared/hooks/authProvider'

dayjs.extend(relativeTime)

export const OrgDialog = ({
  avatar,
  orgUser,
}: {
  avatar: string
  orgUser: any
}) => {
  const [open, setOpen] = useState(false)
  const [isCreatingOrg, setIsCreatingOrg] = useState(false)
  const [orgName, setOrgName] = useState('')
  const { createOrgUser } = useOrgUsersApi()
  const { updateUser } = useUsersApi()
  const { orgId } = useOrgIdApi()
  const { createOrganization, organizations, organization } = useOrgsApi({
    orgId,
  })
  const { session } = useAuth()
  const queryClient = useQueryClient()

  const handleCreateOrg = async () => {
    const res = await createOrganization.mutateAsync({ name: orgName })
    const orgId = res.data?.[0].id

    await createOrgUser.mutateAsync({
      organization_id: orgId!,
      user_id: session?.user?.id!,
      role_id: Roles.ADMIN,
    })

    await queryClient.invalidateQueries({ queryKey: [queryKeys.organizations] })

    setIsCreatingOrg(false)
    setOrgName('')
  }

  const CreateOrg = () => {
    return (
      <Column className="gap-2">
        <Row className="items-center">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsCreatingOrg(false)}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h2 className="text-lg ">New organization</h2>
        </Row>

        <Card>
          <Column className="gap-4">
            <Input
              label="Org. name"
              id="orgName"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder="Enter organization name"
            />

            <Row className="justify-end mt-4 items-center">
              <Button disabled onClick={handleCreateOrg}>
                Create
              </Button>
            </Row>
          </Column>
        </Card>
      </Column>
    )
  }

  const Organizations = () => {
    return (
      <ScrollArea className="h-[250px] mt-2">
        <div className="grid grid-cols-2 gap-4">
          {organizations?.data?.map((org) => (
            <Card
              className="h-[150px] cursor-pointer hover:bg-gray-5"
              onClick={async () => {
                // @ts-ignore
                await updateUser.mutateAsync({
                  id: session?.user?.id!,
                  current_org_id: org.organization?.id!,
                })
                window.location.reload()
              }}
            >
              <Column className="h-full">
                <p className="text-sm ">{org.organization?.name}</p>
                <p className="text-sm text-neutral-500">
                  Created{' '}
                  {dayjs(org.organization?.created_at).format('MMM DD, YYYY')}
                </p>
              </Column>
            </Card>
          ))}
        </div>
      </ScrollArea>
    )
  }

  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-2 p-2 rounded-xl">
      <Row className="items-center gap-2 ">
        <Avatar size="sm" src={avatar} />
        <Column className="gap-[2px]">
          <Row
            className="items-center gap-2 rounded-lg cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <span className="text-[15px]">{organization?.data?.name}</span>
            <ArrowRightLeft className="h-3 w-3" />
          </Row>

          <p
            className="text-xs cursor-pointer"
            onClick={() => navigate(paths.userSettings)}
          >
            {orgUser?.data?.user?.first_name
              ? `${orgUser?.data?.user?.first_name} ${orgUser?.data?.user?.last_name}`
              : 'Click to add info'}
          </p>
        </Column>
      </Row>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="h-[390px]">
          {isCreatingOrg ? (
            CreateOrg()
          ) : (
            <Column className="gap-2">
              <Row className="items-center gap-2">
                <h2 className="text-lg ">Organizations</h2>
                <Button
                  className="w-fit"
                  variant="transparent"
                  onClick={() => setIsCreatingOrg(true)}
                >
                  <CirclePlus className="mr-2 h-4 w-4" />
                  Add
                </Button>
              </Row>

              <Organizations />

              <Button
                className="w-fit"
                onClick={() => {
                  setOpen(false)
                  navigate(paths.organization)
                }}
              >
                <Settings className="mr-2 h-4 w-4" />
                Go to organization settings
              </Button>
            </Column>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
