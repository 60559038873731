import { useNotifications } from 'src/api/db/apiNotifications'

import { DataCard } from './DataCard'

export const Activities = () => {
  const { notifications } = useNotifications()

  const activities =
    notifications?.data
      ?.filter(({ tag }) => tag?.includes('activity'))
      .slice(0, 10) || []
  const emails =
    notifications?.data
      ?.filter(({ tag }) => tag?.includes('email'))
      .slice(0, 10) || []

  return (
    <div className="grid grid-cols-6 gap-3 text-xs">
      <div className="col-span-3">
        <DataCard
          data={activities}
          cardTitle="User activity"
          description="Extension will scan every 10 minutes"
        />
      </div>

      <div className="col-span-3">
        <DataCard
          data={emails}
          cardTitle="Gmail sync"
          description="Gmail will be scanned manually, or per your schedule."
        />
      </div>
    </div>
  )
}
