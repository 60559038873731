import {
  Activity,
  Building,
  Cable,
  Cctv,
  Chrome,
  Coins,
  FlaskConical,
  Table,
} from 'lucide-react'
import React from 'react'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgVendors } from 'src/api/db/apiOrgVendors'
import { useToolsApi } from 'src/api/db/apiTools'
import { paths } from 'src/routes/paths'
import { Row } from 'src/shared/components/Semantic/all'

type Links = {
  label: React.ReactNode
  href: string
  id?: string
  icon: React.ReactNode
  children?: Links[]
}

export const useLinks = (): Links[] => {
  const { orgVendors } = useOrgVendors()
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tools_ = React.useMemo(() => tools, [tools])

  return [
    {
      label: 'Insights',
      href: paths.software_insights,
      icon: <FlaskConical className="text-white/85 h-3 w-3 flex-shrink-0" />,
    },
    {
      label: (
        <Row className="flex items-center gap-2 relative">
          <span>Licences</span>
          <p className="text-[10px] text-blue-500  bg-white rounded-full px-1 py-0.5 absolute right-[-27px]">
            {tools_?.tools?.filter((v) => v.status === 'in_stack').length}
          </p>
        </Row>
      ),
      href: paths.software_table,
      icon: <Table className="text-white/85 h-3 w-3 flex-shrink-0" />,
    },
    {
      label: 'Cost',
      id: 'sources-id',
      href: paths.receipts,
      icon: <Coins className="text-white/85 h-3 w-3 flex-shrink-0" />,
      children: [
        {
          label: 'Sources',
          href: paths.receipts + '?tab=email',
          icon: <Cable className="text-white/85 h-3 w-3 flex-shrink-0" />,
        },
        {
          label: (
            <Row className="flex items-center gap-2 relative">
              <span>Detected</span>
              <p className="text-[10px] text-orange-500  bg-white rounded-full px-1 py-0.5 absolute right-[-27px]">
                {
                  orgVendors?.data?.filter(
                    (v) =>
                      v.status === 'not_in_stack' && v.source === 'email_scan'
                  ).length
                }
              </p>
            </Row>
          ),
          href: paths.software_untracked + '/email_scan',
          icon: <Cctv className="text-white/85 h-3 w-3 flex-shrink-0" />,
        },
      ],
    },
    {
      label: 'Usage',
      id: 'usage-id',
      href: paths.usage,
      icon: <Activity className="text-white/85 h-3 w-3 flex-shrink-0" />,
      children: [
        {
          label: 'Browser extension',
          href: paths.usage,
          icon: <Chrome className="text-white/85 h-3 w-3 flex-shrink-0" />,
        },
        {
          label: (
            <Row className="flex items-center gap-2 relative">
              <span>Detected</span>
              <p className="text-[10px] text-orange-500  bg-white rounded-full px-1 py-0.5 absolute right-[-27px]">
                {
                  orgVendors?.data?.filter(
                    (v) =>
                      v.status === 'not_in_stack' &&
                      v.source === 'extension_scan'
                  ).length
                }
              </p>
            </Row>
          ),
          href: paths.software_untracked + '/extension_scan',
          icon: <Cctv className="text-white/85 h-3 w-3 flex-shrink-0" />,
        },
      ],
    },
    // {
    //   label: 'Costs',
    //   href: paths.costs,
    //   icon: <ChartColumn className="text-white/85 h-3 w-3 flex-shrink-0" />,
    // },
    {
      label: 'Organization',
      href: paths.organization,
      icon: <Building className="text-white/85 h-3 w-3 flex-shrink-0" />,
    },
  ]
}
