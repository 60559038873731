import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useMemo } from 'react'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useUserActivityApi } from 'src/api/db/apiUserActivity'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card, ScrollArea, Tooltip } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'

dayjs.extend(relativeTime)

export const Matrix = () => {
  const { orgId } = useOrgIdApi()
  const { userActivitiesAll } = useUserActivityApi({ org_id: orgId || '' })

  const userToolMatrix = useMemo(() => {
    const matrix = new Map()

    userActivitiesAll?.data?.forEach((activity) => {
      const userId = activity.org_user?.user?.email
      const toolId = activity.tool?.id

      if (!userId || !toolId) return

      if (!matrix.has(userId)) {
        matrix.set(userId, {
          user: activity.org_user?.user,
          tools: new Map(),
        })
      }

      const userEntry = matrix.get(userId)
      if (!userEntry.tools.has(toolId)) {
        userEntry.tools.set(toolId, {
          tool: activity.tool,
          activities: [],
        })
      }

      userEntry.tools.get(toolId).activities.push(activity)
    })

    return matrix
  }, [userActivitiesAll?.data])

  return (
    <Column className="gap-4 w-full">
      <Card className="p-3">
        <ScrollArea className="w-full">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Software used</TableHead>
              </TableRow>
            </TableHeader>

            {Array.from(userToolMatrix.entries()).map(([userId, userData]) => (
              <TableRow key={userId}>
                <TableCell>
                  <Badge className="rounded-full font-normal p-3">
                    {userData.user?.email}
                  </Badge>
                </TableCell>

                <TableCell>
                  <Row className="gap-2">
                    {Array.from(userData.tools.entries()).map((entry) => {
                      const [toolId, toolData] = entry as [
                        string,
                        { tool: any; activities: any[] },
                      ]

                      return (
                        <Tooltip
                          key={toolId}
                          content={
                            <Column>
                              <p>
                                Tracking domain:{' '}
                                <span className="font-bold">
                                  {toolData.tool?.root_domain}
                                </span>
                              </p>

                              <p>
                                Visits by user:{' '}
                                <span className="font-bold">
                                  {toolData.activities.length}
                                </span>
                              </p>
                            </Column>
                          }
                          delayDuration={0}
                        >
                          <span>
                            <Badge
                              variant={'secondary'}
                              key={toolId}
                              className="rounded-full p-2"
                            >
                              <SaasIcon
                                toolName={toolData.tool?.name}
                                src={toolData.tool?.logo_url || ''}
                                size="sm"
                              />

                              <span className="text-xs ml-1 font-normal">
                                {toolData.tool?.name} (
                                {toolData.activities.length})
                              </span>
                            </Badge>
                          </span>
                        </Tooltip>
                      )
                    })}
                  </Row>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </ScrollArea>
      </Card>
    </Column>
  )
}
