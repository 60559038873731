import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { useOrgIdApi } from './apiOrgId'
import { queryKeys } from './queryKeys'

export type InsertEmailAccount =
  Database['public']['Tables']['email_account']['Insert']
export type UpdateEmailAccount =
  Database['public']['Tables']['email_account']['Update']

export const useEmailAccountsApi = ({
  accountId,
  email,
}: {
  accountId?: string
  email?: string
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { orgId } = useOrgIdApi()

  const { data: emailAccount } = useQuery({
    queryKey: [queryKeys.emailAccount, accountId],
    queryFn: async () =>
      await supabase.from('email_account').select('*').eq('id', accountId!),
    enabled: !!accountId,
  })

  const { data: emailAccountFromEmail } = useQuery({
    queryKey: [queryKeys.emailAccount, email],
    queryFn: async () =>
      await supabase.from('email_account').select('*').eq('email', email!),
    enabled: !!email,
  })

  const { data: emailAccountsFromOrg } = useQuery({
    queryKey: [queryKeys.emailAccounts, orgId],
    queryFn: async () =>
      await supabase
        .from('email_account')
        .select('*')
        .eq('organization_id', orgId!),
    enabled: !!orgId,
  })

  const createEmailAccount = useMutation({
    mutationFn: async (data: InsertEmailAccount) =>
      await supabase.from('email_account').insert(data).select('id'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.emailAccounts] })
    },
  })

  const updateEmailAccount = useMutation({
    mutationFn: async (updateData: UpdateEmailAccount) =>
      await supabase
        .from('email_account')
        .update(updateData)
        .eq('id', updateData.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.emailAccount] })

      toast({
        variant: 'success',
        title: 'Updated email account',
      })
    },
  })

  const deleteEmailAccount = useMutation({
    mutationFn: async (id: number) =>
      await supabase.from('email_account').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.emailAccounts] })
    },
  })

  return {
    emailAccount,
    emailAccountFromEmail,
    emailAccountsFromOrg,
    createEmailAccount,
    updateEmailAccount,
    deleteEmailAccount,
  }
}
