import dayjs from 'dayjs'
import JSZip from 'jszip'
import {
  Download,
  Filter,
  HelpingHand,
  Info,
  Mail,
  MailSearch,
  SendHorizontal,
  Settings,
  X,
} from 'lucide-react'
import { useState } from 'react'
import { useReceiptsApi } from 'src/api/db/useReceiptsApi'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  Button,
  Input,
} from 'src/shared/components/ui'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'

import { ReceiptsTable } from '../Receipt_Table'
import { AccountsDialog } from '../components/AccountsDialog'
import { DatePickerInbox } from '../components/DatePickerInbox'
import { SendReceiptsDialog } from '../components/SendReceiptsDialog'
import { SettingsDialog } from '../components/SettingsDialog'

export const Email = () => {
  const { receiptsByOrg } = useReceiptsApi({})
  const [selectedRows, setSelectedRows] = useState([])

  const [filterMonth, setFilterMonth] = useState<string>('all')
  const [filterYear, setFilterYear] = useState<string>('all')

  const [filterEmail, setFilterEmail] = useState<string>('all')
  const [filterType, setFilterType] = useState<string>('all')
  const [filterStatus, setFilterStatus] = useState<string>('all')
  const [searchTerm, setSearchTerm] = useState('')

  const [dialogOpen, setDialogOpen] = useState(false)
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false)

  const [emails_date_from, setEmailsDateFrom] = useState<Date | undefined>()
  const [emails_date_to, setEmailsDateTo] = useState<Date | undefined>()

  const [invoice_date_from, setInvoiceDateFrom] = useState<Date | undefined>()
  const [invoice_date_to, setInvoiceDateTo] = useState<Date | undefined>()

  const [invoice_due_from, setInvoiceDueFrom] = useState<Date | undefined>()
  const [invoice_due_to, setInvoiceDueTo] = useState<Date | undefined>()

  const filteredReceipts = receiptsByOrg?.data
    ?.filter((receipt) => {
      const matchesSearch =
        searchTerm === '' ||
        receipt.sender?.name?.toLowerCase().includes(searchTerm.toLowerCase())

      const matchesMonth =
        filterMonth === 'all' ||
        dayjs(receipt.email_received).format('MMMM').toLowerCase() ===
          filterMonth.toLowerCase()

      const matchesEmail =
        filterEmail === 'all' || receipt.email_recipient === filterEmail

      const matchesType = filterType === 'all' //|| receipt.sender?.type === filterType

      const matchesEmailDates =
        (!emails_date_from ||
          dayjs(receipt.email_received).isAfter(emails_date_from)) &&
        (!emails_date_to ||
          dayjs(receipt.email_received).isBefore(emails_date_to))

      const matchesInvoiceDates =
        (!invoice_date_from ||
          dayjs(receipt.date_of_invoice).isAfter(invoice_date_from)) &&
        (!invoice_date_to ||
          dayjs(receipt.date_of_invoice).isBefore(invoice_date_to))

      const matchesInvoiceDueDates =
        (!invoice_due_from ||
          dayjs(receipt.due_date).isAfter(invoice_due_from)) &&
        (!invoice_due_to || dayjs(receipt.due_date).isBefore(invoice_due_to))

      const matchesYear =
        filterYear === 'all' ||
        dayjs(receipt.email_received).format('YYYY') === filterYear

      const matchesStatus =
        filterStatus === 'all' ||
        (filterStatus === 'warning' && Boolean(receipt.warning_info)) ||
        (filterStatus === 'no_warning' && !Boolean(receipt.warning_info)) ||
        (filterStatus === 'due_soon' &&
          dayjs(receipt.due_date).isAfter(new Date()))

      return (
        matchesSearch &&
        matchesMonth &&
        matchesEmail &&
        matchesType &&
        matchesEmailDates &&
        matchesInvoiceDates &&
        matchesInvoiceDueDates &&
        matchesYear &&
        matchesStatus
      )
    })
    ?.sort((a, b) => b.email_received!.localeCompare(a.email_received!))

  const uniqueEmails = Array.from(
    new Set(
      receiptsByOrg?.data?.map((r) => r.email_recipient).filter(Boolean)
    ) || []
  )

  const sendEmails = () => {
    setEmailDialogOpen(true)
  }

  const downloadUrls = () => {
    const urls = filteredReceipts
      ?.map((receipt) => receipt.receipt_file)
      .filter(Boolean)

    if (!urls?.length) return

    const zip = new JSZip()

    urls.forEach((url, i) => {
      fetch(url!)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = `${filterMonth}_receipts${decodeURIComponent(
            url!.split('public/receipts')[1]!
          )}`

          zip.file(filename, blob)

          if (i === urls.length - 1) {
            zip.generateAsync({ type: 'blob' }).then((zipBlob) => {
              const link = document.createElement('a')
              link.href = URL.createObjectURL(zipBlob)
              link.download = `${filterMonth}_receipts.zip`
              link.click()
            })
          }
        })
    })
  }

  return (
    <div className="">
      <Row className="gap-2 items-center mb-1 py-1">
        <Row className="gap-2 items-center ml-auto">
          <Button
            variant="light"
            className="h-[36px] w-[36px]"
            size="icon"
            onClick={() => setSettingsDialogOpen(true)}
          >
            <Settings className="w-4 h-4" />
          </Button>

          <Button
            variant="light"
            onClick={downloadUrls}
            disabled={selectedRows?.length === 0}
          >
            <Download className="w-4 h-4 mr-2" />
            Download ({selectedRows?.length})
          </Button>

          <Button
            variant="light"
            onClick={sendEmails}
            disabled={selectedRows?.length === 0}
          >
            <SendHorizontal className="w-4 h-4 mr-2" />
            Send ({selectedRows?.length})
          </Button>

          <Button onClick={() => setDialogOpen(true)}>
            <MailSearch className="w-4 h-4 mr-2" />
            Scan emails
          </Button>
        </Row>
      </Row>

      <Row className="gap-2 items-center mt-2">
        <Input
          className="w-full"
          placeholder="Filter by sender"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Select onValueChange={setFilterYear} defaultValue={filterYear}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="All years" />
          </SelectTrigger>

          <SelectContent>
            <SelectItem value="all">All years</SelectItem>
            <SelectItem value="2025">
              2025 (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('YYYY') === '2025'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="2024">
              2024 (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('YYYY') === '2024'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="2023">
              2023 (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('YYYY') === '2023'
                ).length
              }
              )
            </SelectItem>
          </SelectContent>
        </Select>

        <Select onValueChange={setFilterMonth} defaultValue={filterMonth}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="All months" />
          </SelectTrigger>

          <SelectContent>
            <SelectItem value="all">All months</SelectItem>
            <SelectItem value="january">
              January (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'January'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="february">
              February (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'February'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="march">
              March (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'March'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="april">
              April (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'April'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="may">
              May (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'May'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="june">
              June (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'June'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="july">
              July (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'July'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="august">
              August (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'August'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="september">
              September (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'September'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="october">
              October (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'October'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="november">
              November (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'November'
                ).length
              }
              )
            </SelectItem>
            <SelectItem value="december">
              December (
              {
                receiptsByOrg?.data?.filter(
                  (r) => dayjs(r.email_received).format('MMMM') === 'December'
                ).length
              }
              )
            </SelectItem>
          </SelectContent>
        </Select>

        <Button
          variant="light"
          className="w-fit"
          onClick={() => setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen)}
        >
          <Row className="items-center">
            <Filter className="w-3 h-3 mr-2" />
            <p className="text-sm">Advanced filters</p>
          </Row>
        </Button>
      </Row>

      <Accordion
        className="mt-2"
        type="single"
        collapsible
        value={isAdvancedFiltersOpen ? 'filters' : ''}
        onValueChange={(value) => setIsAdvancedFiltersOpen(value === 'filters')}
      >
        <AccordionItem value="filters">
          <AccordionContent>
            <Column className="gap-2">
              <Row className="gap-2">
                <Select onValueChange={setFilterEmail} defaultValue="all">
                  <SelectTrigger className="w-full ">
                    <SelectValue placeholder="All accounts" />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectItem value="all">
                      <Row className="gap-2 items-center">
                        <Mail className="w-4 h-4" />
                        All accounts
                      </Row>
                    </SelectItem>
                    {uniqueEmails.map((email) => (
                      <SelectItem key={email} value={email || ''}>
                        {email}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select onValueChange={setFilterType} defaultValue="all">
                  <SelectTrigger className="w-full ">
                    <SelectValue placeholder="All types" />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectItem value="all">
                      <Row className="gap-2 items-center">
                        <HelpingHand className="w-4 h-4" />
                        All vendor types
                      </Row>
                    </SelectItem>
                    <SelectItem value="software">Software</SelectItem>
                    <SelectItem value="service">Service</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                  </SelectContent>
                </Select>

                <Select onValueChange={setFilterStatus} defaultValue="all">
                  <SelectTrigger className="w-full ">
                    <SelectValue placeholder="Status" />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectItem value="all">
                      <Row className="gap-2 items-center">
                        <Info className="w-4 h-4" />
                        All statuses
                      </Row>
                    </SelectItem>
                    <SelectItem value="due_soon">Due soon</SelectItem>
                    <SelectItem value="warning">Warning info</SelectItem>
                    <SelectItem value="no_warning">No warning</SelectItem>
                  </SelectContent>
                </Select>
              </Row>

              <Row className="gap-2">
                <Row className="items-center p-1 w-fit pl-3">
                  <DatePickerInbox
                    date={emails_date_from}
                    setDate={setEmailsDateFrom}
                    placeholder="from"
                    label="Emails received"
                  />

                  <DatePickerInbox
                    date={emails_date_to}
                    setDate={setEmailsDateTo}
                    placeholder="to"
                    label="and"
                  />

                  {(emails_date_from || emails_date_to) && (
                    <Button
                      variant="ghost"
                      className="w-fit p-2"
                      onClick={() => {
                        setEmailsDateFrom(undefined)
                        setEmailsDateTo(undefined)
                      }}
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  )}
                </Row>

                <Row className="items-center p-1 w-fit pl-3">
                  <DatePickerInbox
                    date={invoice_due_from}
                    setDate={setInvoiceDueFrom}
                    placeholder="from"
                    label="Invoice due dates"
                  />

                  <DatePickerInbox
                    date={invoice_due_to}
                    setDate={setInvoiceDueTo}
                    placeholder="to"
                    label="and"
                  />

                  {(invoice_due_from || invoice_due_to) && (
                    <Button
                      variant="ghost"
                      className="w-fit p-2"
                      onClick={() => {
                        setInvoiceDueFrom(undefined)
                        setInvoiceDueTo(undefined)
                      }}
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  )}
                </Row>

                <Row className="items-center p-1 w-fit pl-3">
                  <DatePickerInbox
                    date={invoice_date_from}
                    setDate={setInvoiceDateFrom}
                    placeholder="from"
                    label="Date of invoice"
                  />

                  <DatePickerInbox
                    date={invoice_date_to}
                    setDate={setInvoiceDateTo}
                    placeholder="to"
                    label="and"
                  />

                  {(invoice_date_from || invoice_date_to) && (
                    <Button
                      variant="ghost"
                      className="w-fit p-2"
                      onClick={() => {
                        setInvoiceDateFrom(undefined)
                        setInvoiceDateTo(undefined)
                      }}
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  )}
                </Row>
              </Row>
            </Column>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <ReceiptsTable
        receipts={filteredReceipts || []}
        setSelectedRows={setSelectedRows}
      />

      <AccountsDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
      <SendReceiptsDialog
        selectedRows={selectedRows}
        emailDialogOpen={emailDialogOpen}
        setEmailDialogOpen={setEmailDialogOpen}
      />
      <SettingsDialog
        settingsDialogOpen={settingsDialogOpen}
        setSettingsDialogOpen={setSettingsDialogOpen}
      />
    </div>
  )
}
