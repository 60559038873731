import { Chrome } from 'lucide-react'
import { Column } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'

export function ExtensionPage() {
  return (
    <Column className="gap-4 w-full h-[100vh] items-center justify-center bg-theme_light">
      <Card className="">
        <h2 className="text-lg ">You have been invited!</h2>
        <p className="text-sm  text-muted-foreground">
          Click the button below to install the PinnOne extension.
        </p>

        <Button
          className="mt-4"
          onClick={() => {
            window.open(
              'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
              '_blank'
            )
          }}
        >
          <Chrome className="mr-2 w-4 h-4" />
          Download Extension
        </Button>
      </Card>
    </Column>
  )
}
