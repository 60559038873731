import { zodResolver } from '@hookform/resolvers/zod'
import { Building, Save, ShieldQuestion } from 'lucide-react';
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { FormInput, FormWrapper } from 'src/shared/components/FormComponents'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui/button'
import { Card } from 'src/shared/components/ui/card'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { z } from 'zod'

import { useOrgsApi } from '../../api/db/apiOrgs'
import { Billing } from './tabs/Billing'
import { TeamTable } from './tabs/Team'

const formSchema = z.object({
  name: z.string().min(2).max(50),
})

export const OrganizationPage = () => {
  const { orgId } = useOrgIdApi()
  const { organization, updateOrganization } = useOrgsApi({ orgId })
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = searchParams.get('tab') || 'team'

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
    },
  })

  useEffect(() => {
    const organization_ = organization?.data
    if (organization_) {
      methods.setValue('name', organization_.name || '')
    }
  }, [organization?.data])

  const onSubmitUserSettings = (values: z.infer<typeof formSchema>) =>
    updateOrganization.mutate({
      id: orgId || '',
      name: values.name,
    })

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-2">
        <Building />
        <h1 className="text-2xl ">Organization</h1>
      </Row>

      <Tabs
        value={activeTab}
        onValueChange={(value) => setSearchParams({ tab: value })}
      >
        <TabsList className="w-fit">
          <TabsTrigger value="team">Team</TabsTrigger>
          <TabsTrigger value="settings">Settings</TabsTrigger>
          <TabsTrigger value="billing">Billing</TabsTrigger>
          <TabsTrigger value="faq">FAQ</TabsTrigger>
        </TabsList>

        <TabsContent value="team">
          <TeamTable />
        </TabsContent>

        <TabsContent value="billing">
          <Billing />
        </TabsContent>

        <TabsContent value="settings">
          <Column className="gap-4">
            <Card className="w-full justify-center items-center">
              <Column className="gap-2 w-full">
                <FormWrapper onSubmit={onSubmitUserSettings} methods={methods}>
                  <FormInput name="name" label="Org. name" methods={methods} />

                  <Button style={{ marginTop: '1rem' }} type="submit">
                    <Save className="h-4 w-4 mr-2" />
                    Save
                  </Button>
                </FormWrapper>
              </Column>
            </Card>
          </Column>
        </TabsContent>

        <TabsContent value="faq">
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md  ml-1">What data do you store?</h2>
                </Row>

                <p className="text-sm">
                  We try to store as little data as possible. For each extension
                  user, we store a list of user activity. Each user activity
                  contains: email, time of visit, and root domain of a tracked
                  app.
                </p>
              </Column>
            </Card>

            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md  ml-1">
                    How often does the extension scan?
                  </h2>
                </Row>

                <p className="text-sm">
                  The extension scans the activity every 10 minutes. We analyze
                  60 minutes back in time each scan. It usually takes about 10
                  seconds to scan, but up to two minutes if we find and add new
                  vendors.
                </p>
              </Column>
            </Card>

            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md  ml-1">
                    How do you identify the tools?
                  </h2>
                </Row>

                <p className="text-sm">
                  We use a privacy-first AI-based proprietary system to scan the
                  urls from the user activity and identify all business related
                  SaaS applications.
                </p>
              </Column>
            </Card>

            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md  ml-1">
                    How are you compliant and privacy aware?
                  </h2>
                </Row>

                <p className="text-sm">
                  Your full browser history is never stored in any database. We
                  only work with your data locally in the browser extension, and
                  not on our servers. We are fully GDPR compliant.
                </p>

                <p className="text-sm">
                  If you have any questions, reach out to us and we will be
                  happy to answer.
                </p>
              </Column>
            </Card>
          </div>
        </TabsContent>
      </Tabs>
    </Column>
  )
}
