import { zodResolver } from '@hookform/resolvers/zod'
import { MagicWandIcon } from '@radix-ui/react-icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Receipt } from 'lucide-react'
import { Chrome } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgVendors } from 'src/api/db/apiOrgVendors'
import { useSendersApi } from 'src/api/db/apiSenders'
import { useToolsApi } from 'src/api/db/apiTools'
import { paths } from 'src/routes/paths'
import {
  FormCheckbox,
  FormInput,
  FormTextarea,
} from 'src/shared/components/FormComponents'
import { FormSelect } from 'src/shared/components/FormComponents/FormSelect'
import { FormWrapper } from 'src/shared/components/FormComponents/FormWrapper'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from 'src/shared/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import { departmentOptions } from 'src/shared/consts'
import { z } from 'zod'

dayjs.extend(relativeTime)

const formSchema = z.object({
  id: z.number(),
  organization_id: z.string(),
  org_vendor_id: z.string(),
  sender_id: z.string(),
  owner_org_user_id: z.number(),

  name: z.string(),
  description: z.string(),
  department: z.string(),
  website: z.string(),

  type: z.string(),
  status: z.string(),
  is_tracking: z.boolean(),
  root_domain: z.string(),
  link_to_pricing_page: z.string(),
})

export const Settings = () => {
  const navigate = useNavigate()
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools, updateTool, deleteTool } = useToolsApi({
    organization_id: orgId || '',
  })
  const tool = tools?.tools?.find((t) => t.id === Number(tool_id))
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const { orgVendors, updateOrgVendor } = useOrgVendors()
  const { senders } = useSendersApi({ orgId })
  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (!tool) return
    methods.reset({
      id: tool?.id!,
      organization_id: tool?.organization_id,
      org_vendor_id: tool?.org_vendor_id?.toString() || '0',
      sender_id: tool?.sender_id?.toString() || '0',
      name: tool?.name || undefined,
      description: tool?.description || undefined,
      department: tool?.department || undefined,
      website: tool?.website || undefined,
      type: tool?.type || undefined,
      status: tool?.status || undefined,
      is_tracking: tool?.is_tracking,
      root_domain: tool?.root_domain || undefined,
      link_to_pricing_page: tool?.link_to_pricing_page || undefined,
    })
  }, [tool])

  const handleSubmitTrackCost = async () => {
    const values = methods.getValues()

    await updateTool.mutateAsync({
      id: tool?.id!,
      sender_id:
        Number(values.sender_id) === 0 ? null : Number(values.sender_id),
    })
  }

  const handleSubmitTrackUsage = async () => {
    const values = methods.getValues()

    await updateTool.mutateAsync({
      id: tool?.id!,
      org_vendor_id:
        Number(values.org_vendor_id) === 0
          ? null
          : Number(values.org_vendor_id),
    })
  }

  const handleSubmitGeneralInfo = async () => {
    const values = methods.getValues()

    await updateTool.mutateAsync({
      id: tool?.id!,
      name: values.name,
      description: values.description,
      department: values.department,
      website: values.website,

      type: values.type,
      status: 'in_stack',
      is_tracking: values.is_tracking,
      root_domain: values.root_domain,
      link_to_pricing_page: values.link_to_pricing_page,
    })
  }

  const autofillFromReceipts = () => {
    const sender = senders?.data?.find(
      (sender) => sender.id === Number(methods.getValues('sender_id'))
    )
    methods.setValue('name', sender?.name || '')
    methods.setValue('description', sender?.description || '')
    methods.setValue('department', sender?.category || '')
    methods.setValue('website', sender?.url || '')
    methods.setValue('is_tracking', false)
    methods.setValue('type', 'service')
  }

  const autofillFromExtension = () => {
    const vendor = orgVendors?.data?.find(
      (vendor) => vendor.id === Number(methods.getValues('org_vendor_id'))
    )
    methods.setValue('name', vendor?.name || '')
    methods.setValue('description', vendor?.description || '')
    methods.setValue('department', vendor?.category || '')
    methods.setValue('website', vendor?.url || '')
    methods.setValue('is_tracking', true)
    methods.setValue('root_domain', vendor?.root_domain || '')
    methods.setValue('link_to_pricing_page', vendor?.link_to_pricing_page || '')
    methods.setValue('type', 'software')
  }

  const CostSettings = () => (
    <Card className="w-full justify-center items-center">
      <p className="text-lg ">Cost</p>

      <Column className="gap-1 w-full mt-4">
        <Row>
          <Receipt className="mr-2 h-4 w-4 text-muted-foreground" />
          <p className="text-xs text-muted-foreground ">
            Track cost from receipt vendor
          </p>
        </Row>

        <Select
          value={methods.watch('sender_id')}
          onValueChange={(value) => {
            methods.setValue('sender_id', value, { shouldDirty: true })
          }}
        >
          <SelectTrigger className="mt-2">
            <SelectValue placeholder="Select receipt sender" />
          </SelectTrigger>

          <SelectContent className="text-sm font-medium">
            <SelectItem value="0">No vendor</SelectItem>
            {senders?.data?.map((sender) => (
              <SelectItem key={sender.id} value={sender.id.toString()}>
                {sender.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button className="ml-auto w-fit mt-2" onClick={handleSubmitTrackCost}>
          Save
        </Button>
      </Column>
    </Card>
  )

  const UsageSettings = () => (
    <Card className="w-full justify-center items-center">
      <p className="text-lg ">Usage</p>

      <Column className="gap-1 w-full mt-4">
        <Row>
          <Chrome className="mr-2 h-4 w-4 text-muted-foreground" />
          <p className="text-xs text-muted-foreground ">
            Track usage from extension vendor
          </p>
        </Row>

        <Select
          value={methods.watch('org_vendor_id')}
          onValueChange={(value) => {
            methods.setValue('org_vendor_id', value, { shouldDirty: true })
          }}
        >
          <SelectTrigger className="mt-2">
            <SelectValue placeholder="Select tool" />
          </SelectTrigger>

          <SelectContent className="text-sm font-medium">
            <SelectItem value="0">No vendor</SelectItem>

            {orgVendors?.data
              ?.filter((vendor) => !['ignored'].includes(vendor.status!))
              ?.map((vendor) => (
                <SelectItem key={vendor.id} value={vendor.id.toString()}>
                  {vendor.name} ({vendor.root_domain})
                </SelectItem>
              ))}
          </SelectContent>
        </Select>

        <Button className="ml-auto w-fit mt-2" onClick={handleSubmitTrackUsage}>
          Save
        </Button>
      </Column>
    </Card>
  )

  const GeneralInfo = () => (
    <Card className="w-full justify-center items-center">
      <Row className="items-center gap-2">
        <p className="text-lg ">Tool settings</p>

        <Button
          size="tiny"
          variant="transparent"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            methods.watch('org_vendor_id')
              ? autofillFromExtension()
              : autofillFromReceipts()
          }}
        >
          <MagicWandIcon className="mr-1 h-3 w-3" />
          Autofill
        </Button>
      </Row>

      <Column className="gap-4 w-full mt-3">
        <FormWrapper
          onSubmit={methods.handleSubmit(handleSubmitGeneralInfo)}
          methods={methods}
        >
          <Column className="gap-2">
            <FormInput
              label="Name"
              name="name"
              methods={methods}
              className="w-full"
              type="text"
            />

            <FormTextarea
              label="Description"
              name="description"
              methods={methods}
              className="w-full"
            />

            <FormSelect
              name="department"
              label="Department"
              options={departmentOptions}
              methods={methods}
            />
            <FormSelect
              name="type"
              label="Type"
              options={[
                {
                  value: 'software',
                  label: 'Software',
                },
                {
                  value: 'service',
                  label: 'Service',
                },
                {
                  value: 'other',
                  label: 'Other',
                },
              ]}
              methods={methods}
            />

            <FormInput
              name="website"
              label="Website"
              methods={methods}
              className="w-full"
              type="text"
            />

            <Column className="rounded-md p-4 border bg-white/5">
              <div className="mt-1">
                <FormCheckbox
                  name="is_tracking"
                  label="Track usage with chrome extension"
                  methods={methods}
                />
              </div>

              {methods.watch('is_tracking') && (
                <FormInput
                  name="root_domain"
                  label="URL to track"
                  methods={methods}
                  className="w-full"
                  type="text"
                  description={`All domains containing ${methods.watch(
                    'root_domain'
                  )} will be tracked for usage. For example: ${methods.watch(
                    'root_domain'
                  )}, ${methods.watch('root_domain')}/dashboard, etc.`}
                />
              )}
            </Column>

            <Row className="gap-2 mt-4">
              <Button className="ml-auto" onClick={handleSubmitGeneralInfo}>
                Save
              </Button>
            </Row>
          </Column>
        </FormWrapper>
      </Column>
    </Card>
  )

  const RemoveTool = () => (
    <Card>
      <Row className="justify-between items-center w-full">
        <Column className="gap-2">
          <p className="">Remove from stack</p>
          <p className="text-sm text-gray-500">
            Remove this tool from the stack.
          </p>
        </Column>

        <Dialog open={isRemoveModalOpen} onOpenChange={setIsRemoveModalOpen}>
          <DialogContent>
            <DialogHeader>
              <h1 className="text-xl ">Remove tool</h1>
            </DialogHeader>

            <p className="text-sm  text-muted-foreground">
              Are you sure you want to remove this tool from your stack? This
              action cannot be undone.
            </p>

            <Row className="justify-end gap-2 mt-4">
              <Button
                variant="light"
                onClick={() => setIsRemoveModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={async () => {
                  await deleteTool
                    .mutateAsync({
                      id: tool?.id!,
                    })
                    .then(async () => {
                      if (tool?.org_vendor_id) {
                        await updateOrgVendor.mutateAsync({
                          id: tool.org_vendor_id,
                          status: 'not_in_stack',
                        })
                      }

                      navigate(paths.software_table)
                      setIsRemoveModalOpen(false)
                    })
                }}
              >
                Remove
              </Button>
            </Row>
          </DialogContent>
        </Dialog>

        <Button
          variant="destructive"
          onClick={() => setIsRemoveModalOpen(true)}
        >
          Remove
        </Button>
      </Row>
    </Card>
  )

  return (
    <Column className="gap-4 w-[700px] mx-auto">
      <Row className="gap-4 w-full">
        {CostSettings()}
        {UsageSettings()}
      </Row>

      {GeneralInfo()}
      {RemoveTool()}
    </Column>
  )
}
