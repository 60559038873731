import { useMutation } from '@tanstack/react-query'
import { useToast } from 'src/shared/hooks/use-toast'

import { ServerRoutes } from './serverRoutes'

interface SendReceiptsProps {
  sendType?: string
  fromEmail: string
  toEmail: string
  fileUrls: string[]
  organization_id: string
  org_user_id: number
}

export const useSendReceipts = () => {
  const toast = useToast()

  return useMutation({
    mutationFn: async (props: SendReceiptsProps) => {
      const response = await fetch(
        process.env.REACT_APP_BE_SERVER_URL + ServerRoutes.sendReceipts,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fromEmail: props.fromEmail,
            toEmail: props.toEmail,
            fileUrls: props.fileUrls,
            sendType: props.sendType,
            organization_id: props.organization_id,
            org_user_id: props.org_user_id,
          }),
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.msg || 'Failed to send receipts')
      }

      return response
    },
    onSuccess: () => {
      toast.toast({
        title: 'Receipts sent successfully',
        variant: 'success',
        duration: 5000,
      })
    },
    onError: (error: Error) => {
      toast.toast({
        title: 'Something went wrong',
        description: error.message,
        variant: 'destructive',
        duration: 5000,
      })
    },
  })
}
