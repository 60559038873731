import { Receipt_ } from 'src/api/db/useReceiptsApi'

import { divisorMap } from '../consts'

const convertToUSD = (price: number, currency: string) => {
  if (currency === 'USD') return price
  if (currency === 'EUR') return price * 1.049
  if (currency === 'SEK') return price * 0.0942
  if (currency === 'GBP') return price * 1.265
  return price
}

// ------- Base calculations -------
export const calculateMonthlyPrice = (receipts?: Receipt_[]) => {
  if (!receipts) return 0

  let totalMonthlyPrice = 0
  receipts.forEach((receipt) => {
    let monthlyPrice = 0
    const divisor = divisorMap[receipt.renewal_frequency!] // Divide by 12, 4, or 1

    if (receipt?.pricing_model === 'PER_SEAT') {
      const pricePerSeat = convertToUSD(
        receipt?.price_per_seat!,
        receipt?.currency!
      )

      const numberOfSeats = receipt?.number_of_seats!
      const totalPrice = pricePerSeat * numberOfSeats

      monthlyPrice = totalPrice / divisor
    }

    if (receipt?.pricing_model === 'FLAT_FEE')
      monthlyPrice =
        convertToUSD(receipt?.flat_fee_cost!, receipt?.currency!) / divisor

    if (receipt?.pricing_model === 'USAGE_BASED')
      monthlyPrice =
        convertToUSD(receipt?.usage_based_cost!, receipt?.currency!) / divisor

    if (receipt?.pricing_model === 'OTHER')
      monthlyPrice =
        convertToUSD(receipt?.other_cost!, receipt?.currency!) / divisor

    totalMonthlyPrice += monthlyPrice
  })

  const countUniqueMonths = (dois: Receipt_[]) => {
    const uniqueMonths = new Set()

    dois.forEach((receipt) => {
      const date = new Date(receipt.date_of_invoice!)
      const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`
      uniqueMonths.add(yearMonth)
    })

    return uniqueMonths.size
  }

  const uniqueMonths = countUniqueMonths(receipts)
  return Math.round(totalMonthlyPrice / uniqueMonths)
}

export const calculateQuarterlyPrice = (receipts?: Receipt_[]) =>
  Math.round(calculateMonthlyPrice(receipts) * 3)

export const calculateAnnualPrice = (receipts?: Receipt_[]) =>
  Math.round(calculateMonthlyPrice(receipts) * 12)

// ------- Formatted calculations -------
export const calculateMonthlyPriceFormatted = (receipts?: Receipt_[]) => {
  if (!receipts) return 'N/A'

  let formattedPrice = 'N/A'
  const monthlyPrice = calculateMonthlyPrice(receipts)
  const pricingModel = receipts[0]?.pricing_model

  if (pricingModel === 'FLAT_FEE') {
    formattedPrice = `$${monthlyPrice} / monthly`
  }

  if (pricingModel === 'PER_SEAT') {
    const pricePerSeat = convertToUSD(
      receipts[0]?.price_per_seat!,
      receipts[0]?.currency!
    )
    const numberOfSeats = receipts[0]?.number_of_seats!
    const totalPrice = pricePerSeat * numberOfSeats

    formattedPrice = `($${pricePerSeat} x ${numberOfSeats} seats) $${totalPrice} / monthly`
  }

  if (pricingModel === 'USAGE_BASED') {
    formattedPrice = `$${monthlyPrice} / monthly`
  }

  if (pricingModel === 'OTHER') {
    formattedPrice = `$${monthlyPrice}`
  }

  return formattedPrice
}

export const calculateQuarterlyPriceFormatted = (receipts?: Receipt_[]) => {
  if (!receipts) return 'N/A'

  let formattedPrice = 'N/A'
  const quarterlyPrice = calculateQuarterlyPrice(receipts)
  const pricingModel = receipts[0]?.pricing_model

  if (pricingModel === 'FLAT_FEE') {
    formattedPrice = `$${quarterlyPrice} / quarterly`
  }

  if (pricingModel === 'PER_SEAT') {
    const pricePerSeat = convertToUSD(
      receipts[0]?.price_per_seat!,
      receipts[0]?.currency!
    )
    const numberOfSeats = receipts[0]?.number_of_seats!
    const totalPrice = pricePerSeat * numberOfSeats

    formattedPrice = `($${pricePerSeat} x ${numberOfSeats} seats) $${totalPrice} / quarterly`
  }

  if (pricingModel === 'USAGE_BASED') {
    formattedPrice = `$${quarterlyPrice} / quarterly`
  }

  if (pricingModel === 'OTHER') {
    formattedPrice = `$${quarterlyPrice} / quarterly`
  }

  return formattedPrice
}

export const calculateAnnualPriceFormatted = (receipts?: Receipt_[]) => {
  if (!receipts) return 'N/A'
  const annualPrice = calculateAnnualPrice(receipts)

  return `$${annualPrice} / annually`
}
