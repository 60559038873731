import { useGoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import {
  ArrowRight,
  Download,
  Loader,
  Lock,
  MailSearchIcon,
  SendHorizonal,
  ShieldCheck,
} from 'lucide-react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEmailAccountsApi } from 'src/api/db/apiEmailAccounts'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useOrgsApi } from 'src/api/db/apiOrgs'
import { queryKeys } from 'src/api/db/queryKeys'
import { useScanEmailAccount } from 'src/api/server/scanEmailAccount'
import { ServerRoutes } from 'src/api/server/serverRoutes'
import { useInviteExtUsers } from 'src/api/server/useInviteExtUsers'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card, Image } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'
import { Textarea } from 'src/shared/components/ui/textarea'
import { useAuth } from 'src/shared/hooks/authProvider'

import { Card1 } from './Card1'
import img1 from './images/1.png'
import imag1 from './images/11.png'
import imag2 from './images/22.png'
import excel from './images/excel.png'
import gmail from './images/gmail.png'
import logo from './images/logo.png'
import pdfs from './images/pdfs.png'

export const OnboardingPage = () => {
  const { session } = useAuth()
  const { emailAccountsFromOrg } = useEmailAccountsApi({
    email: session?.user?.email,
  })
  const { orgId } = useOrgIdApi()
  const { orgUser } = useOrgUsersApi()
  const scanEmailAccount = useScanEmailAccount()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { step = 1 } = useParams()
  const [extensionEmails, setExtensionEmails] = useState('')
  const { updateOrganization } = useOrgsApi({})
  const [isScanning, setIsScanning] = useState(false)
  const [loading, setLoading] = useState(false)
  const inviteExtensionUsers = useInviteExtUsers()

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: [
      // // Restricted scopes
      'https://www.googleapis.com/auth/gmail.readonly',
    ].join(' '),
    onSuccess: async (codeResponse) => {
      console.log(codeResponse)
      setLoading(true)
      const tokens = await fetch(
        process.env.REACT_APP_BE_SERVER_URL + ServerRoutes.googleAuth,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: codeResponse.code,
            organization_id: orgId,
          }),
        }
      )

      if (tokens) {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.emailAccounts],
        })
        setLoading(false)
        navigate('/onboarding/' + (Number(step) + 1))
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  })

  const [selected, setSelected] = useState<
    'gmail' | 'excel' | 'coupa' | 'pdfs'
  >('gmail')

  const handleInviteExtension = async () => {
    await inviteExtensionUsers
      .mutateAsync({
        emails: extensionEmails,
        organization_id: orgId || '',
      })
      .then(() => {
        setExtensionEmails('')
        queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
        navigate('/onboarding/' + (Number(step) + 1))
      })
  }

  const SelectDataSource = () => (
    <>
      <h1 className="mt-12 text-center text-sm tracking-wider uppercase bg-clip-text text-transparent bg-gradient-to-r from-[#3546DF] to-[hsl(198,77%,55%)] font-bold">
        SELECT DATA SOURCE
      </h1>

      <h1 className="text-[#26282B] text-[30px] font-bold leading-[38px] mt-2">
        Where do you want to look for licenses?
      </h1>

      <Card className="mt-12 w-[600px] flex flex-col items-center">
        <Column className="gap-4">
          <Card1
            title="Gmail"
            description="Analyze spend from invoices and receipts in your gmail."
            icon={gmail}
            onClick={() => setSelected('gmail')}
            cardId="gmail"
            selected={selected}
          />

          <Card1
            title="PDF files"
            description="Drag and drop your invoices and receipts."
            icon={pdfs}
            onClick={() => {}}
            cardId="pdfs"
            selected={selected}
            disabled
          />
          <Card1
            title="CSV file"
            description="Transactions from your bank or accounting software."
            icon={excel}
            onClick={() => {}}
            cardId="excel"
            selected={selected}
            disabled
          />
        </Column>

        <Button
          className="mt-4 p-6 px-8"
          disabled={!selected}
          onClick={() => navigate('/onboarding/' + (Number(step) + 1))}
        >
          Next
          <ArrowRight className="h-4 w-4 ml-2" />
        </Button>
      </Card>
    </>
  )

  const GmailPermissions = () => (
    <>
      <h1 className="mt-12 text-center text-sm tracking-wider uppercase bg-clip-text text-transparent bg-gradient-to-r from-[#3546DF] to-[hsl(198,77%,55%)] font-bold">
        GMAIL PERMISSIONS
      </h1>

      <h1 className="text-[#26282B] text-[30px] font-bold leading-[38px] mt-2">
        Which permissions will be needed?
      </h1>

      <Card className="mt-12 w-[600px] flex flex-col items-center">
        <Column className="gap-3 mt-2">
          <Image
            src={img1}
            alt="receipts"
            className="w-[60%] mx-auto rounded-lg"
          />

          <Badge className="w-fit mx-auto bg-green-500/10">
            <ShieldCheck className="w-4 h-4 mr-2 text-green-500" />

            <p className="text-sm font-medium mx-auto text-center text-green-500">
              Needed for scanning receipts from your inbox.
            </p>
          </Badge>
        </Column>

        <Button
          className="mt-4 p-6 px-8 mx-auto"
          onClick={() => navigate('/onboarding/' + (Number(step) + 1))}
        >
          Next
          <ArrowRight className="w-4 h-4 ml-2" />
        </Button>
      </Card>
    </>
  )

  const GmailVerification = () => (
    <>
      <h1 className="mt-12 text-center text-sm tracking-wider uppercase bg-clip-text text-transparent bg-gradient-to-r from-[#3546DF] to-[hsl(198,77%,55%)] font-bold">
        VERIFICATION
      </h1>

      <h1 className="text-[#26282B] text-[30px] font-bold leading-[38px] mt-2">
        Google verification
      </h1>

      <Card className="mt-12 max-w-[600px] flex flex-col items-center">
        <Column>
          <Button
            isLoading={loading}
            className="p-6 px-8 mx-auto"
            onClick={() => {
              googleLogin()
            }}
          >
            Ok, let's go!
          </Button>

          <i className="text-sm font-normal text-gray-500 mx-auto text-center mt-4">
            We are waiting for Google to verify our app.
          </i>

          <Image
            src={imag1}
            alt="gmail"
            className="w-[70%] mx-auto object-cover rounded-lg border mt-2"
          />

          <Image
            src={imag2}
            alt="gmail"
            className="w-[70%] mx-auto object-cover rounded-lg border mt-4"
          />
        </Column>
      </Card>
    </>
  )

  const ScanEmail = () => (
    <>
      <h1 className="mt-12 text-center text-sm tracking-wider uppercase bg-clip-text text-transparent bg-gradient-to-r from-[#3546DF] to-[hsl(198,77%,55%)] font-bold">
        SCAN EMAIL
      </h1>

      <h1 className="text-[#26282B] text-[30px] font-bold leading-[38px] mt-2">
        Find licenses from receipts
      </h1>

      <Card className="mt-12 max-w-[600px] flex flex-col items-center">
        <Column className="gap-4 items-center">
          {isScanning ? (
            <Column>
              <p className="text-sm text-muted-foreground text-center">
                <span className="flex items-center">
                  <Loader className="w-4 h-4 mr-2 animate-spin" /> We are
                  looking for licences in the background.
                </span>
              </p>

              <Button
                className="mt-4 p-6 px-8 mx-auto"
                onClick={() => navigate('/onboarding/' + (Number(step) + 1))}
              >
                Next
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </Column>
          ) : (
            <Button
              isLoading={scanEmailAccount.isLoading}
              onClick={() => {
                scanEmailAccount.mutate({
                  email: emailAccountsFromOrg?.data?.[0]?.email || '',
                  organization_id: orgId,
                  org_user_id: orgUser?.data?.id!,
                  after: format(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() - 1,
                      1
                    ),
                    'yyyy/M/d'
                  ),
                  before: format(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      0
                    ),
                    'yyyy/M/d'
                  ),
                })

                setIsScanning(true)
              }}
            >
              <MailSearchIcon className="w-4 h-4 mr-2" />
              Scan last month
            </Button>
          )}

          <Row className="gap-2">
            <Button
              isLoading={loading}
              size={'tiny'}
              variant="ghost"
              className="w-fit"
              onClick={() =>
                window.open(
                  'https://cautious-elbow-be7.notion.site/PinnOne-Security-Compliance-18f015b4f55480fc84adda046f5826bf?pvs=73',
                  '_blank'
                )
              }
            >
              <ShieldCheck className="w-3 h-3 mr-1" />
              About security
            </Button>

            <Button
              isLoading={loading}
              size={'tiny'}
              variant="ghost"
              className="w-fit"
              onClick={() =>
                window.open(
                  'https://myaccount.google.com/connections',
                  '_blank'
                )
              }
            >
              <Lock className="w-3 h-3 mr-1" />
              Your permissions
            </Button>
          </Row>
        </Column>
      </Card>
    </>
  )

  const InviteTeam = () => (
    <>
      <h1 className="mt-12 text-center text-sm tracking-wider uppercase bg-clip-text text-transparent bg-gradient-to-r from-[#3546DF] to-[hsl(198,77%,55%)] font-bold">
        TRACK USER ACTIVITY
      </h1>

      <h1 className="text-[#26282B] text-[30px] font-bold leading-[38px] mt-2">
        Detect licences with low usage
      </h1>

      <Card className="mt-12 w-[450px] flex flex-col items-center">
        <Column className="gap-2 items-center w-full">
          <Button
            isLoading={loading}
            className="w-full"
            variant="secondary"
            onClick={() =>
              window.open(
                'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
                '_blank'
              )
            }
          >
            <Download className="w-3 h-3 mr-1" />
            Download extension
          </Button>

          <Column className="w-full mt-4 gap-2 items-center">
            <p className="text-sm text-muted-foreground text-center w-full">
              Invite your team to install the extension.
            </p>

            <Textarea
              className="bg-white"
              placeholder="user1@example.com, user2@example.com"
              rows={5}
              value={extensionEmails}
              onChange={(e) => setExtensionEmails(e.target.value)}
            />

            <p className="text-xs text-muted-foreground">
              Use comma to separate emails.
            </p>
          </Column>

          <Column>
            <Row className="items-center mt-4 gap-2">
              <Button
                disabled={!extensionEmails}
                className="ml-auto"
                onClick={handleInviteExtension}
                isLoading={inviteExtensionUsers.isLoading}
              >
                Send email invitations
                <SendHorizonal className="ml-2 h-4 w-4" />
              </Button>
            </Row>

            <Button
              variant="ghost"
              onClick={() => {
                navigate('/onboarding/' + (Number(step) + 1))
              }}
              className="text-xs text-muted-foreground h-8 ml-auto"
            >
              Skip →
            </Button>
          </Column>
        </Column>
      </Card>
    </>
  )

  const InviteTeamDone = () => (
    <>
      <h1 className="text-[#26282B] text-[30px] font-bold leading-[38px] mt-12">
        And now we wait!
      </h1>

      <Card className="mt-12 max-w-[600px] flex flex-col items-center">
        <Column className="gap-4 items-center">
          <p className="text-sm text-left w-full">Onboarding complete! 🎉</p>

          <p className="text-sm text-left w-full text-muted-foreground">
            When your invitations are accepted, and has collected enough data,
            we will send you an email with the results. <br />
            <br />
            <span className="font-semibold text-primary">
              This may take a few hours or a few days.
            </span>
          </p>

          <Button
            className="mt-4"
            isLoading={updateOrganization.isLoading}
            onClick={() => {
              updateOrganization
                .mutateAsync({
                  id: orgId,
                  onboarded: true,
                })
                .then(() => {
                  queryClient.invalidateQueries({
                    queryKey: [queryKeys.org_id],
                  })
                  queryClient.invalidateQueries({
                    queryKey: [queryKeys.organization],
                  })

                  navigate('/onboarding-done')
                })
            }}
          >
            Browse around while waiting for email
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </Column>
      </Card>
    </>
  )

  return (
    <div className="p-14 flex items-center justify-center relative bg-theme_light">
      <Column className="items-center">
        <Image
          src={logo}
          alt="logo"
          className="w-[130px] h-auto opacity-25 mx-auto"
        />

        {step === '1' && <SelectDataSource />}
        {step === '2' && <GmailPermissions />}
        {step === '3' && <GmailVerification />}
        {step === '4' && <ScanEmail />}
        {step === '5' && InviteTeam()}
        {step === '6' && <InviteTeamDone />}
      </Column>
    </div>
  )
}
