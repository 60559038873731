import { useQueryClient } from '@tanstack/react-query'
import {
  BookMarked,
  Calendar,
  Computer,
  Loader2,
  MailCheck,
  PersonStanding,
  Save,
  Smartphone,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useUsersApi } from 'src/api/db/apiUsers'
import { queryKeys } from 'src/api/db/queryKeys'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/shared/components/ui/dialog'
import { Input } from 'src/shared/components/ui/input'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'
import { Tabs, TabsList, TabsTrigger } from 'src/shared/components/ui/tabs'

import { SettingsRow } from './SettingsRow'

export const SettingsDialog = ({
  settingsDialogOpen,
  setSettingsDialogOpen,
}: {
  settingsDialogOpen: boolean
  setSettingsDialogOpen: (open: boolean) => void
}) => {
  const [loading, setLoading] = useState(false)
  const [emailFilter, setEmailFilter] = useState('')
  const { user } = useUsersApi()
  const { orgUser, updateOrgUser } = useOrgUsersApi()
  const queryClient = useQueryClient()
  const [emailTo, setEmailTo] = useState('')
  const [sendType, setSendType] = useState('')

  useEffect(() => {
    setEmailFilter(orgUser?.data?.email_filter || '')
  }, [orgUser?.data?.email_filter])

  useEffect(() => {
    setSendType(orgUser?.data?.auto_accounting_send_type!)
  }, [orgUser?.data?.auto_accounting_send_type])

  useEffect(() => {
    setEmailTo(orgUser?.data?.auto_accounting_to_email || '')
  }, [orgUser?.data?.auto_accounting_to_email])

  return (
    <Dialog open={settingsDialogOpen} onOpenChange={setSettingsDialogOpen}>
      <DialogContent>
        <DialogHeader className="pb-4">
          <DialogTitle>Settings</DialogTitle>
        </DialogHeader>

        <ScrollArea className="h-[340px]">
          <Column className="gap-4">
            <SettingsRow
              title={
                <Row className="gap-1 items-center">
                  <BookMarked className="w-4 h-4" /> Auto-accounting
                </Row>
              }
              description="Automatically scan and send invoices to your accountant at the end of each month."
              onChange={async () => {
                await updateOrgUser
                  // @ts-ignore
                  .mutateAsync({
                    id: orgUser?.data?.id!,
                    auto_accounting: !orgUser?.data?.auto_accounting,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({
                      queryKey: [queryKeys.org_user],
                    })
                  })
              }}
              checked={orgUser?.data?.auto_accounting!}
              content={
                <Row className="gap-2 w-full mt-2">
                  <Tabs
                    defaultValue={sendType}
                    onValueChange={async (sendType) => {
                      setSendType(sendType)
                      await updateOrgUser
                        // @ts-ignore
                        .mutateAsync({
                          id: orgUser?.data?.id!,
                          auto_accounting_send_type: sendType,
                        })
                        .then(() => {
                          queryClient.invalidateQueries({
                            queryKey: [queryKeys.org_user],
                          })
                        })
                    }}
                    className="mr-auto"
                  >
                    <TabsList>
                      <TabsTrigger
                        value="to_accountant"
                        className="rounded-full"
                      >
                        <PersonStanding className="w-4 h-4 mr-2" />
                        To accountant
                      </TabsTrigger>

                      <TabsTrigger value="to_system" className="rounded-full">
                        <Computer className="w-4 h-4 mr-2" />
                        To system
                      </TabsTrigger>
                    </TabsList>

                    <p className="text-xs text-gray-500 ml-2 mt-1">
                      {sendType === 'to_accountant'
                        ? 'Send as a compressed file.'
                        : 'Send each receipt as a separate mail.'}
                    </p>
                  </Tabs>

                  <Input
                    className="bg-transparent border"
                    placeholder="Email address"
                    value={emailTo}
                    onChange={(e) => setEmailTo(e.target.value)}
                  />

                  <Button
                    className="w-[45px]"
                    variant="ghost"
                    size="icon"
                    onClick={async () => {
                      setLoading(true)
                      await updateOrgUser
                        // @ts-ignore
                        .mutateAsync({
                          id: orgUser?.data?.id!,
                          auto_accounting_to_email: emailTo,
                        })
                        .then(() => {
                          setTimeout(() => {
                            setLoading(false)
                          }, 700)
                        })
                    }}
                  >
                    <Save className="w-4 h-4" />
                  </Button>
                </Row>
              }
            />

            <SettingsRow
              title={
                <Row className="gap-1 items-center">
                  <Smartphone className="w-4 h-4" /> SMS
                </Row>
              }
              disabled={!user?.data?.phone}
              description="Get a text message when an invoice is due."
              content={
                !user?.data?.phone && (
                  <Link
                    to={paths.userSettings}
                    className="text-xs text-gray-500 underline"
                  >
                    Start with adding your phone number
                  </Link>
                )
              }
              onChange={async () => {
                await updateOrgUser
                  // @ts-ignore
                  .mutateAsync({
                    id: orgUser?.data?.id!,
                    sms_reminders: !orgUser?.data?.sms_reminders,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({
                      queryKey: [queryKeys.org_user],
                    })
                  })
              }}
              checked={orgUser?.data?.sms_reminders!}
            />

            <SettingsRow
              title={
                <Row className="gap-1 items-center">
                  <Calendar className="w-4 h-4" /> Calendar
                </Row>
              }
              description="Add invoices to your calendar as reminders."
              onChange={async () => {
                await updateOrgUser
                  // @ts-ignore
                  .mutateAsync({
                    id: orgUser?.data?.id!,
                    calendar_reminders: !orgUser?.data?.calendar_reminders,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({
                      queryKey: [queryKeys.org_user],
                    })
                  })
              }}
              checked={orgUser?.data?.calendar_reminders!}
            />

            <SettingsRow
              title={
                <Row className="gap-1 items-center">
                  <MailCheck className="w-4 h-4" /> Sort Gmail
                </Row>
              }
              description="Automatically put all your receipts and invoices in a folder."
              onChange={async () => {
                await updateOrgUser
                  // @ts-ignore
                  .mutateAsync({
                    id: orgUser?.data?.id!,
                    sort_gmail: !orgUser?.data?.sort_gmail,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({
                      queryKey: [queryKeys.org_user],
                    })
                  })
              }}
              checked={orgUser?.data?.sort_gmail!}
            />

            <SettingsRow
              title={
                <Row className="gap-1 items-center">
                  <MailCheck className="w-4 h-4" /> Filter out emails to scan
                </Row>
              }
              content={
                <Row className="gap-2 w-full">
                  <Column className="gap-2 w-full">
                    <Input
                      className="bg-transparent border"
                      placeholder="Comma separated list of emails"
                      value={emailFilter}
                      onChange={(e) => {
                        setEmailFilter(e.target.value)
                      }}
                    />
                  </Column>

                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={async () => {
                      setLoading(true)
                      await updateOrgUser
                        // @ts-ignore
                        .mutateAsync({
                          id: orgUser?.data?.id!,
                          email_filter: emailFilter,
                        })
                        .then(() => {
                          setTimeout(() => {
                            setLoading(false)
                          }, 700)
                        })
                    }}
                  >
                    {loading ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : (
                      <Save className="w-4 h-4" />
                    )}
                  </Button>
                </Row>
              }
              description="Exclude emails from being scanned with filters"
              onChange={async () => {
                await updateOrgUser
                  // @ts-ignore
                  .mutateAsync({
                    id: orgUser?.data?.id!,
                    filter_emails: !orgUser?.data?.filter_emails,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({
                      queryKey: [queryKeys.org_user],
                    })
                  })
              }}
              checked={orgUser?.data?.filter_emails!}
            />
          </Column>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
