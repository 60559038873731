import { Chrome } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'

import { Activity } from './tabs/Activity'
import { Matrix } from './tabs/Matrix'

export const UsagePage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = searchParams.get('tab') || 'matrix'

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-2">
        <Chrome />
        <h1 className="text-2xl ">Browser extension</h1>
      </Row>

      <Tabs
        value={activeTab}
        onValueChange={(value) => setSearchParams({ tab: value })}
      >
        <TabsList className="w-fit">
          <TabsTrigger value="matrix">User table</TabsTrigger>
          <TabsTrigger value="activity">All activity</TabsTrigger>
        </TabsList>

        <TabsContent value="matrix">
          <Matrix />
        </TabsContent>

        <TabsContent value="activity">
          <Activity />
        </TabsContent>
      </Tabs>
    </Column>
  )
}
