import { Blocks, MousePointerClick, TriangleAlert, Wallet } from 'lucide-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { useUserActivityApi } from 'src/api/db/apiUserActivity'
import { useUntrackedTools } from 'src/api/db/helpers/useUntrackedTools'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'

import { SpendByCategory } from '../../components/SpendByCategory'
import { StatCard } from '../../components/StatCard'
import MonthCalendar from './MonthCalendar'
// @ts-ignore
import videoFile from './movie.mp4'

export const Overview = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams()

  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId || '',
  })
  const untrackedTools = useUntrackedTools(orgId || '')
  const { userActivitiesAll } = useUserActivityApi({ org_id: orgId || '' })

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-4">
        <StatCard
          title="Low usage"
          value={
            '$' + Math.round(tools?.calculated?.totalAnnualWasteLowUsage || 0)
          }
          icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
          description="Potential savings"
          color={
            tools?.calculated?.totalAnnualWasteLowUsage! > 0 ? 'green' : null
          }
          onClick={() => setSearchParams({ tab: 'cost-reduction' })}
        />

        <StatCard
          title="Seat utilization"
          value={
            '$' +
            Math.round(tools?.calculated?.totalAnnualWasteSeatUtilization || 0)
          }
          icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
          description="Potential savings"
          color={
            tools?.calculated?.totalAnnualWasteSeatUtilization! > 0
              ? 'green'
              : null
          }
          onClick={() => setSearchParams({ tab: 'cost-reduction' })}
        />

        <StatCard
          title="New detected licences"
          value={untrackedTools}
          icon={
            <TriangleAlert
              className={`w-6 h-6 ${
                untrackedTools > 0 ? 'text-orange-500' : 'text-muted-foreground'
              }`}
            />
          }
          description="Click to review"
          onClick={() => navigate(paths.software_untracked)}
        />
      </Row>

      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3">
          <MonthCalendar />
        </div>

        <div className="col-span-2 gap-4">
          <Column className="gap-4">
            {userActivitiesAll?.data?.length === 0 && (
              <Card
                className={'flex justify-between items-center gap-4 w-full'}
              >
                <Column className="w-full h-full items-center justify-center">
                  <p className="text-sm mb-2 text-center mt-2 text-muted-foreground">
                    When installed, the extension will scan for
                    <br />
                    tools and activity every{' '}
                    <span className="bg-gradient-to-r from-orange-400 to-orange-600 bg-clip-text text-transparent">
                      10 minutes
                    </span>
                    .
                  </p>

                  <p className="text-sm mb-2 text-muted-foreground">
                    You can also start scan manually ↓
                  </p>

                  <video className="h-[300px] w-fit rounded-[10px]" controls>
                    <source src={videoFile} type="video/mp4" />
                  </video>
                </Column>
              </Card>
            )}

            <Row className="gap-4">
              <StatCard
                title="Tracked tools"
                value={`${tools?.tools?.filter(
                  (tool) => tool.status === 'in_stack'
                ).length}`}
                icon={<Blocks className="w-6 h-6 text-muted-foreground" />}
                description="Last 30 days"
                onClick={() => navigate(paths.software_table)}
              />

              <StatCard
                title="Active users"
                value={tools?.calculated?.totalActiveUsers || 0}
                icon={
                  <MousePointerClick className="w-6 h-6 text-muted-foreground" />
                }
                description="Last 30 days"
              />
            </Row>

            <Row className="gap-4">
              <SpendByCategory />
            </Row>
          </Column>
        </div>
      </div>
    </Column>
  )
}
