import { useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { Column } from 'src/shared/components/Semantic/all'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

export const CostCard = () => {
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tool = tools?.tools?.find((tool) => tool.id === Number(tool_id))

  // @ts-ignore
  const latestReceipt = tool?.sender?.receipt.sort((a, b) =>
    b.date_of_invoice!.localeCompare(a.date_of_invoice!)
  )[0]

  if (!latestReceipt)
    return (
      <Column className="gap-2 mt-6">
        <p className="text-xs text-muted-foreground">
          There are no receipts connected to this tool.
        </p>
      </Column>
    )

  return (
    <Table className="rounded-lg mt-4">
      <TableBody>
        <TableRow>
          <TableCell className="p-2 text-xs font-medium text-muted-foreground">
            Renewal frequency
          </TableCell>
          <TableCell className="text-right p-2">
            <Badge variant="light">{latestReceipt?.renewal_frequency}</Badge>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="p-2 text-xs font-medium text-muted-foreground">
            Next renewal
          </TableCell>
          <TableCell className="text-right p-2">
            {`${tool?.calculated?.nextRenewalDate} (in ${tool?.calculated?.renewsIn} days)`}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="p-2 text-xs font-medium text-muted-foreground">
            Pricing model
          </TableCell>
          <TableCell className="text-right p-2">
            <Badge variant="light">{latestReceipt?.pricing_model}</Badge>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="p-2 text-xs font-medium text-muted-foreground">
            Monthly price
          </TableCell>
          <TableCell className="text-right p-2">
            {latestReceipt?.price_month_formatted}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="p-2 text-xs font-medium text-muted-foreground">
            Annualized spend
          </TableCell>
          <TableCell className="text-right p-2">
            {latestReceipt?.price_annual_formatted}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
