import { CreditCard } from 'lucide-react'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { Column, Row } from 'src/shared/components/Semantic/all'

import { StatCard } from '../Software_Insights/components/StatCard'
import { AreaChartComponent } from './AreaChart'
import { BarChartComponent } from './BarChart'

export const Costs = () => {
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId || '',
  })

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-4">
        <StatCard
          title="Annual spend"
          value={`$${Math.round(tools?.calculated?.annualSpend || 0)}`}
          icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
          description="Average annual spend"
        />

        <StatCard
          title="Monthly spend"
          value={`$${Math.round(tools?.calculated?.monthlySpend || 0)}`}
          icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
          description="Average monthly spend"
        />
      </Row>
      <BarChartComponent />

      <AreaChartComponent />
    </Column>
  )
}
