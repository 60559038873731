import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Table as TableIcon } from 'lucide-react'
import * as React from 'react'
import { useState } from 'react'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Alert } from 'src/shared/components/ui/alert'
import { Input } from 'src/shared/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'

import { useColumns } from './columns'
import { AddSubscriptionDialog } from './components/AddSubscriptionDialog'

export const SubscriptionsPage = () => {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'spendMonthly',
      desc: true,
    },
  ])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeFilter, setTypeFilter] = React.useState<string>('all')
  const columns = useColumns()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tools_ = React.useMemo(() => tools, [tools])

  const tableData = React.useMemo(
    () =>
      tools_?.tools
        ?.filter((tool) => tool.status === 'in_stack')
        // .filter((tool) => {
        //   if (typeFilter === 'all') return true
        //   return tool.type === typeFilter
        // })
        .map((tool) => {
          return {
            app: {
              name: tool?.name || '',
              id: tool.id || '',
              logo_url: tool.org_vendor?.logo_url || '',
            },
            dept: tool.department || '',
            owner: tool.budget_owner?.user?.email || '',
            type: tool.type || '',
            spendMonthly: tool.calculated?.priceMonth,
            spendAnnual: tool.calculated?.priceAnnual,
            tracking_usage: tool.is_tracking,
            tracking_cost: tool.sender_id,
          }
        }) ?? [],
    [tools_, typeFilter]
  )

  const table = useReactTable({
    data: tableData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      pagination: {
        pageIndex: 0,
        pageSize: 10000,
      },
    },
  })

  return (
    <Column className="gap-4 w-full h-full">
      <Row className="gap-2 items-center mb-1 py-2">
        <TableIcon />
        <h1 className="text-2xl ">Licences</h1>
      </Row>

      <div className="w-full">
        <Row className="pb-3 gap-2">
          <Row className="gap-2 items-center">
            <Input
              placeholder="Filter services..."
              value={(table.getColumn('app')?.getFilterValue() as string) ?? ''}
              onChange={(event) =>
                table.getColumn('app')?.setFilterValue(event.target.value)
              }
              className="max-w-sm"
            />

            {/* <Select value={typeFilter} onValueChange={setTypeFilter}>
              <SelectTrigger className="w-[180px] mr-auto ">
                <SelectValue placeholder="Filter by type" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value="all">All services</SelectItem>
                <SelectItem value="software">Software</SelectItem>
                <SelectItem value="service">Service</SelectItem>
              </SelectContent>
            </Select> */}
          </Row>

          <AddSubscriptionDialog
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </Row>

        <div
          className="border rounded-lg bg-white"
          style={{
            height: 'calc(100vh - 190px)',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <Table>
            <TableHeader className="sticky top-0 z-10 bg-theme_dark">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className="h-10 border-l first:border-l-0 border-b text-xs"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="px-4 py-1 border-l first:border-l-0 border-b"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24">
                    <Alert className="w-fit mx-auto my-4">
                      Click <span className="font-bold">Add tools</span> or use{' '}
                      <span className="font-bold">Untracked tools</span> to
                      track activities
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </Column>
  )
}
