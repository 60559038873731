export enum ServerRoutes {
  inviteAdmins = '/invite-admins',
  inviteExtensionUsers = '/invite-extension-users',
  deleteExtensionUser = '/delete-extension-user',
  addToolsManually = '/add-tools-manually',
  generateOverlappingTools = '/generate-overlapping-tools',
  askTeam = '/ask-team',
  sendExtensionInvite = '/send-extension-invite',
  scanEmailAccount = '/scan-email-account',
  sendReceipts = '/email-receipts',
  googleAuth = '/auth/google',
  createCheckoutSession = '/create-checkout-session',
}
