import { useEffect } from 'react'
import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import { useAuth } from 'src/shared/hooks/authProvider'

declare global {
  interface Window {
    chrome?: {
      runtime?: any
    }
  }
}

export function ExtensionLoginPage() {
  const params = new URLSearchParams(window.location.search)
  const extensionId = params.get('extensionId')
  const { session } = useAuth()
  console.info('🚀  session:', session)

  useEffect(() => {
    if (session) {
      login()
    }
  }, [session])

  const login = () =>
    window?.chrome?.runtime?.sendMessage(
      extensionId,
      {
        type: 'login_success',
        payload: {
          user_id: session?.user?.id,
        },
      },
      (_res) => {
        if (window?.chrome?.runtime?.lastError) {
          console.error(window.chrome.runtime.lastError)
          console.error('Failed to communicate with extension')
        } else {
          console.info('Login successful')
        }
      }
    )

  return (
    <Column className="w-full h-[100vh] items-center justify-center bg-theme_light">
      <Card className="">
        <h2 className="text-lg ">You're good to go!</h2>
        <p className="text-sm  text-muted-foreground">
          Your SaaS usage is now being tracked. You can now close this page.
        </p>

        {session && (
          <div className="flex items-center gap-2 mt-4 ml-3">
            <div className="w-2 h-2 rounded-full bg-green-500"></div>
            <span className="text-sm ">Logged in</span>
          </div>
        )}

        {!session && (
          <div className="flex items-center gap-2 mt-4 ml-3">
            <div className="w-2 h-2 rounded-full bg-red-500"></div>
            <span className="text-sm ">Not logged in</span>
          </div>
        )}
      </Card>
    </Column>
  )
}
