import {
  BatteryCharging,
  Check,
  CreditCard,
  Home,
  SendHorizontal,
  Settings,
  SignalHigh,
  UserRound,
  Wifi,
} from 'lucide-react'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'

export const DemoPage = () => {
  const navItems = [
    {
      icon: Home,
      active: true,
    },
    {
      icon: CreditCard,
      active: false,
    },
    {
      icon: UserRound,
      active: false,
    },
    {
      icon: Settings,
      active: false,
    },
  ]

  const BottomNavButton = ({ item }: { item: (typeof navItems)[number] }) => (
    <Button variant={item.active ? 'default' : 'ghost'} size="icon">
      <item.icon className="w-5 h-5" />
    </Button>
  )

  const BottomNav = () => (
    <Row className="absolute bottom-0 left-1/2 -translate-x-1/2 rounded-full p-3 gap-2">
      {navItems.map((item) => (
        <BottomNavButton key={item.icon.name} item={item} />
      ))}
    </Row>
  )

  const Header = () => (
    <Row className=" absolute top-[-32px] left-1/2 -translate-x-1/2 w-[100vw] py-3 px-6 gap-2 flex justify-between items-center">
      <h1 className="font-normal">9:41</h1>

      <Row className="gap-1">
        <SignalHigh className="w-5 h-5" />
        <Wifi className="w-5 h-5" />
        <BatteryCharging className="ml-[4px] w-5 h-5" />
      </Row>
    </Row>
  )

  return (
    <>
      <div className="absolute top-[-32px] left-0 w-full h-full bg-gradient-to-b from-black to-transparent"></div>
      <Column className="relative w-full h-full">
        <Header />
        <div className="mt-3"></div>

        <Column className="my-auto">
          <Card>
            <Column className="gap-2 items-center">
              <div className="bg-gradient-to-r from-green-400 to-green-600 rounded-full p-2 border-2 border-white/20">
                <Check className="w-6 h-6 text-white" />
              </div>

              <h1 className="text-xl font-light text-white">
                Accounting done!
              </h1>

              <h1 className="text-sm font-light text-white">February • 2025</h1>
            </Column>

            <Column className="gap-1 items-center mt-2">
              <Badge className="py-2 px-3 ">
                <SendHorizontal className="w-4 h-4 mr-2" />
                <p>23 receipts scraped and sent to accountants</p>
              </Badge>
            </Column>
          </Card>

          <Column className="gap-1 items-center mt-2">
            <Row className="gap-2 items-center">
              <div className="bg-gradient-to-r from-green-400 to-green-600 rounded-full p-1 border-2 border-white/20" />
              <span>Auto-accounting enabled</span>
            </Row>

            <Row className="gap-2 items-center">
              <div className="bg-gradient-to-r from-green-400 to-green-600 rounded-full p-1 border-2 border-white/20" />
              <span>Auto-organize emails enabled</span>
            </Row>

            <Row className="gap-2 items-center">
              <div className="bg-gradient-to-r from-green-400 to-green-600 rounded-full p-1 border-2 border-white/20" />
              <span>Add due invoices to calendar</span>
            </Row>
          </Column>
          <BottomNav />
        </Column>
      </Column>
    </>
  )
}
