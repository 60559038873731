const rawHTML = `

<h1 class="privacy-policy-h1">Privacy Policy
<br><span>Last Updated On 18-Nov-2024</span>
<br><span>Effective Date 29-Jan-2025</span>
</h1>

<p class="privacy-policy-p">
    This Privacy Policy describes the policies of

    PinnOne,
    Nybrogatan 76,
    Stockholm
    114 41,
    Sweden,
    email: alfredodling@gmail.com,
    phone: 0736868686

    on the collection,
    use and disclosure of your information that we collect
    when you use our website ( app.pinn.one ).
    (the "Service"). By accessing or using
    the
    Service, you are consenting to the collection, use and
    disclosure of your information in accordance with this
    Privacy Policy. If you do not consent to the same,
    please do not access or use the Service.
</p>

<p class="privacy-policy-p">
    We may modify this Privacy Policy at any time without
    any prior notice to you and will post the revised
    Privacy Policy on the Service. The revised Policy will
    be effective 180 days from when the
    revised Policy is posted in the Service and your
    continued access or use of the Service after such time
    will constitute your acceptance of the revised Privacy
    Policy. We therefore recommend that you periodically
    review this page.
</p>

<ol class="privacy-policy-ol">
        <li>
        <h2 class="privacy-policy-h2">
            Information We Collect:
        </h2>

        
        <p class="privacy-policy-p">
            We will collect and process the following
            personal information about you:
        </p>
        <ol class="privacy-policy-ol">
            <li>Name</li>
            <li>Email</li>
            <li>Mobile</li>
            <li>Payment Info</li>
            <li>Chrome browser activity via chrome extension</li>
            <li>Gmail data including:
                <ul>
                    <li>Email messages and settings</li>
                    <li>Email labels</li>
                    <li>Tasks data including task lists, task details and task status</li>
                </ul>
            </li>
        </ol>
        </li>

    
        <li>
        <h2 class="privacy-policy-h2">
            How We Collect Your Information:
        </h2>

        <p class="privacy-policy-p">
            We collect/receive information about you in the
            following manner:
        </p>
        <ol class="privacy-policy-ol">
            <li>When a user fills up the registration form or otherwise submits personal information</li>
            <li>Interacts with the website</li>
            <li>From public sources</li>
            <li>Through Google APIs when you grant us permission to access your Gmail and Tasks data. Specifically, we use the following Google API scopes:
                <ul>
                    <li>gmail.readonly - to view your email messages and settings</li>
                    <li>gmail.modify - to read, compose and send emails from your Gmail account</li>
                    <li>gmail.labels - to see and edit your email labels</li>
                    <li>tasks - to create, edit, organize and delete your tasks</li>
                    <li>tasks.readonly - to view your tasks</li>
                </ul>
            </li>
        </ol>
        </li>

        <li>
        <h2 class="privacy-policy-h2">
            How We Use Your Information:
        </h2>
        <p class="privacy-policy-p">
            We will use the information that we collect
            about you for the following purposes:
        </p>
        <ol class="privacy-policy-ol">
        <li>Creating user account</li>
        <li>Administration info</li>
        <li>Processing and analyzing your Gmail data to provide email management features</li>
        <li>Managing and organizing your tasks through Google Tasks integration</li>
        <li>Improving our service functionality and user experience</li>
        </ol>
        <p class="privacy-policy-p">
            If we want to use your information for any other
            purpose, we will ask you for consent and will
            use your information only on receiving your
            consent and then, only for the purpose(s) for
            which grant consent unless we are required to do
            otherwise by law.
        </p>
    </li>
    <li>
        <h2 class="privacy-policy-h2">
            How We Share Your Information:
        </h2>
        <p class="privacy-policy-p">
            We will not transfer your personal information
            to any third party without seeking your consent,
            except in limited circumstances as described
            below:
        </p>
        <ol class="privacy-policy-ol">
                        <li>Analytics</li>
                <li>Data collection & process</li>
                    </ol>
        
        <p class="privacy-policy-p">
            We require such third party's to use the
            personal information we transfer to them only
            for the purpose for which it was transferred and
            not to retain it for longer than is required for
            fulfilling the said purpose.
        </p>
        <p class="privacy-policy-p">
            We may also disclose your personal information
            for the following: (1) to comply with applicable
            law, regulation, court order or other legal
            process; (2) to enforce your agreements with us,
            including this Privacy Policy; or (3) to respond
            to claims that your use of the Service violates
            any third-party rights. If the Service or our
            company is merged or acquired with another
            company, your information will be one of the
            assets that is transferred to the new owner.
        </p>
    </li>
    

        <li>
        <h2 class="privacy-policy-h2">
            Retention Of Your Information:
        </h2>
        <p class="privacy-policy-p">
            We will retain your personal information with us
            for
                        90 days to 2 years after user accounts remain idle
                        or for as
            long as we need it to fulfill the purposes for
            which it was collected as detailed in this
            Privacy Policy. We may need to retain certain
            information for longer periods such as
            record-keeping / reporting in accordance with
            applicable law or for other legitimate reasons
            like enforcement of legal rights, fraud
            prevention, etc. Residual anonymous information
            and aggregate information, neither of which
            identifies you (directly or indirectly), may be
            stored indefinitely.
        </p>
    </li>
    
    <li>
        <h2 class="privacy-policy-h2">
            Your Rights:
        </h2>
        <p class="privacy-policy-p">
            Depending on the law that applies, you may have
            a right to access and rectify or erase your
            personal data or receive a copy of your personal
            data, restrict or object to the active
            processing of your data, ask us to share (port)
            your personal information to another entity,
            withdraw any consent you provided to us to
            process your data, a right to lodge a complaint
            with a statutory authority and such other rights
            as may be relevant under applicable laws. To
            exercise these rights, you can write to us at
            alfredodling@gmail.com.
            We will respond to your
            request in accordance with applicable law.
        </p>

        
        <p class="privacy-policy-p">
            Do note that if you do not allow us to collect
            or process the required personal information or
            withdraw the consent to process the same for the
            required purposes, you may not be able to access
            or use the services for which your information
            was sought.
        </p>
    </li>
        <li>
        <h2 class="privacy-policy-h2">
            Cookies Etc.
        </h2>
        <p class="privacy-policy-p">

            
            To learn more about how we use these
            and your choices in relation to these tracking
            technologies, please refer to our
            <a href="app.pinn.one/cookieConsent">Cookie Policy.</a>
        </p>
    </li>
    
    <li>
        <h2 class="privacy-policy-h2">
            Security:
        </h2>
        <p class="privacy-policy-p">
            The security of your information is important to
            us and we will use reasonable security measures
            to prevent the loss, misuse or unauthorized
            alteration of your information under our
            control. However, given the inherent risks, we
            cannot guarantee absolute security and
            consequently, we cannot ensure or warrant the
            security of any information you transmit to us
            and you do so at your own risk.
        </p>
    </li>

    
    <li>
        <h2 class="privacy-policy-h2">
            Grievance / Data Protection Officer:
        </h2>
        <p class="privacy-policy-p">
            If you have any queries or concerns about the
            processing of your information that is available
            with us, you may email our Grievance Officer at
            PinnOne,
            Nybrogatan 76,
            email: alfredodling@gmail.com.
            We will address your concerns in accordance with applicable law.
        </p>
    </li>
</ol>
<p class="privacy-policy-p">
    Privacy Policy generated with  <a target="_blank" href="https://www.cookieyes.com/?utm_source=PP&utm_medium=footer&utm_campaign=UW">CookieYes</a>.
</p>
`

export const PrivacyPolicy = () => (
  <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
)
