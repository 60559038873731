import { CaretDownIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Chrome, RocketIcon, SendHorizonal } from 'lucide-react'
import { AlertCircle } from 'lucide-react'
import { useState } from 'react'
import * as React from 'react'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { queryKeys } from 'src/api/db/queryKeys'
import { useInviteAdmins } from 'src/api/server/useInviteAdmins'
import { useInviteExtUsers } from 'src/api/server/useInviteExtUsers'
import { Row } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import { Alert, AlertDescription } from 'src/shared/components/ui/alert'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from 'src/shared/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'
import { Input } from 'src/shared/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'
import { Textarea } from 'src/shared/components/ui/textarea'
import { cn } from 'src/shared/components/ui/utils'

import { useColumns } from './columns'
import { TeamMember } from './consts'

export function TeamTable() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isInviteAdminModalOpen, setIsInviteAdminModalOpen] = useState(false)
  const [isInviteExtensionModalOpen, setIsInviteExtensionModalOpen] =
    useState(false)
  const [adminEmails, setAdminEmails] = useState('')
  const [extensionEmails, setExtensionEmails] = useState('')
  const queryClient = useQueryClient()
  const saved = JSON.parse(localStorage.getItem('onboardingState') || '{}')

  const inviteAdmins = useInviteAdmins()
  const inviteExtensionUsers = useInviteExtUsers()

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})
  const columns = useColumns()
  const { orgUsers } = useOrgUsersApi()
  const { orgId } = useOrgIdApi()

  const data = React.useMemo(
    () =>
      (orgUsers?.data
        ?.filter(({ removed }) => !removed)
        .map(({ user, created_at, role, id }) => ({
          id: user?.id,
          org_user_id: id,
          name: user?.first_name
            ? `${user?.first_name} ${user?.last_name}`
            : '-',
          joined: created_at,
          email: user?.email,
          role_id: role?.id,
        })) as TeamMember[]) || [],

    [orgUsers?.data]
  )

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const teamLimit = 3
  const handleInviteAdmins = async () =>
    await inviteAdmins
      .mutateAsync({ emails: adminEmails, organization_id: orgId || '' })
      .then(() => {
        setAdminEmails('')
        setIsInviteAdminModalOpen(false)
        queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
      })

  const handleInviteExtension = async () => {
    await inviteExtensionUsers
      .mutateAsync({
        emails: extensionEmails,
        organization_id: orgId || '',
      })
      .then(() => {
        setExtensionEmails('')
        setIsInviteExtensionModalOpen(false)
        queryClient.invalidateQueries({ queryKey: [queryKeys.org_users] })
      })
  }

  return (
    <Card>
      <div className="w-full">
        <Row className="gap-2 items-center mb-4">
          <Input
            placeholder="Filter name..."
            value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
            onChange={(event) =>
              table.getColumn('name')?.setFilterValue(event.target.value)
            }
            className="max-w-sm"
          />

          <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                className={cn('ml-auto', saved?.step2 ? '' : '')} //animate-pulse
              >
                Actions
                <CaretDownIcon className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  setIsInviteAdminModalOpen(true)
                  setMenuOpen(false)
                }}
              >
                Invite admins
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setIsInviteExtensionModalOpen(true)
                  setMenuOpen(false)
                }}
              >
                Invite extension users
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => {
                  window.open(
                    'https://chromewebstore.google.com/detail/pinnone-beta/naadkflophinjbdfehdcpbkbdmddncbd',
                    '_blank'
                  )
                  setMenuOpen(false)
                }}
              >
                Download extension
                <Chrome className="ml-2 h-4 w-4 text-primary" />
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </Row>

        <div
          className="border rounded-lg bg-white"
          style={{
            height: 'calc(100vh - 350px)',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <Table>
            <TableHeader className="sticky top-0 z-10 bg-theme_dark">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24">
                    <Alert variant="default" className="mt-4 w-fit mx-auto">
                      <Row className="gap-2 items-center">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>
                          Invite users to your organization to get started
                        </AlertDescription>
                      </Row>
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length} of{' '}
            {table.getFilteredRowModel().rows.length} row(s) selected.
          </div>

          <div className="space-x-2">
            <Button
              variant="light"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>

            <Button
              variant="light"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>

        <Dialog
          open={isInviteAdminModalOpen}
          onOpenChange={setIsInviteAdminModalOpen}
        >
          <DialogContent>
            <DialogHeader>
              <h1 className="text-xl ">Invite admins</h1>
            </DialogHeader>

            <p className="text-sm  text-muted-foreground">
              Invite admins by entering one or more email addresses. Separate by
              commas.
            </p>

            <Textarea
              className="mt-4"
              placeholder="admin@example.com, admin2@example.com, admin3@example.com"
              rows={5}
              value={adminEmails}
              onChange={(e) => setAdminEmails(e.target.value)}
            />

            <Row className="justify-between">
              <Badge className="mt-4 rounded-2xl" variant="light">
                {teamLimit -
                  (orgUsers?.data?.length || 0) +
                  1 -
                  adminEmails.split(',').length}{' '}
                / {teamLimit} users
              </Badge>

              <Button
                className="ml-auto mt-4"
                onClick={handleInviteAdmins}
                isLoading={inviteAdmins.isLoading}
                disabled={
                  (orgUsers?.data?.length || 0) +
                    adminEmails.split(',').length -
                    1 >
                  teamLimit - 1
                }
              >
                Send invites
                <SendHorizonal className="ml-2 h-4 w-4" />
              </Button>
            </Row>
          </DialogContent>
        </Dialog>

        <Dialog
          open={isInviteExtensionModalOpen}
          onOpenChange={setIsInviteExtensionModalOpen}
        >
          <DialogContent>
            <DialogHeader>
              <h1 className="text-xl">Invite extension users</h1>
            </DialogHeader>

            <Alert className="mt-4">
              <RocketIcon className="h-4 w-4" />
              <AlertDescription>
                In order to track activity, your teammates need to activate
                their accounts and install our browser extension.
              </AlertDescription>
            </Alert>

            <Textarea
              className="mt-4"
              placeholder="extension@example.com, extension2@example.com, extension3@example.com"
              rows={5}
              value={extensionEmails}
              onChange={(e) => setExtensionEmails(e.target.value)}
            />

            <Row className="justify-between">
              {/* <Badge className="mt-4 rounded-2xl" variant="light">
                {teamLimit -
                  (orgUsers?.data?.length || 0) +
                  extensionEmails.split(',').length}{' '}
                users
              </Badge> */}

              <Row className="items-center mt-4 ml-auto">
                <Button
                  className="ml-auto"
                  onClick={handleInviteExtension}
                  isLoading={inviteExtensionUsers.isLoading}
                  disabled={
                    extensionEmails.length === 0 ||
                    (orgUsers?.data?.length || 0) +
                      extensionEmails.split(',').length -
                      1 >
                      teamLimit - 1
                  }
                >
                  Send invites
                  <SendHorizonal className="ml-2 h-4 w-4" />
                </Button>
              </Row>
            </Row>
          </DialogContent>
        </Dialog>
      </div>
    </Card>
  )
}
