import { CaretDownIcon } from '@radix-ui/react-icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Cctv, Chrome, ExternalLink, Mail, SendHorizonal } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useOrgVendors } from 'src/api/db/apiOrgVendors'
import { useOrgsApi } from 'src/api/db/apiOrgs'
import { useToolsApi } from 'src/api/db/apiTools'
import { useAskTeam } from 'src/api/server/useAskTeam'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card, Tooltip } from 'src/shared/components/ui'
import { Alert } from 'src/shared/components/ui/alert'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/shared/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'
import { Input } from 'src/shared/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import { Switch } from 'src/shared/components/ui/switch'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'
import { Tabs, TabsList, TabsTrigger } from 'src/shared/components/ui/tabs'
import { Textarea } from 'src/shared/components/ui/textarea'

import { cronToDayOfWeek, cronToFrequency, getCronExpression } from './utils'

dayjs.extend(relativeTime)

export const UntrackedTablePage = () => {
  const { orgId } = useOrgIdApi()
  const { orgVendors, updateOrgVendor } = useOrgVendors()
  const { addTool } = useToolsApi({})
  const { orgUser } = useOrgUsersApi()
  const [filterStatus, setFilterStatus] = useState<string>('not_in_stack')
  const [filterText, setFilterText] = useState('')
  const { organization, updateOrganization } = useOrgsApi({
    orgId: orgId || '',
  })
  const { type } = useParams()
  const [tab, setTab] = useState<'email_scan' | 'extension_scan'>(
    type === 'email_scan' ? 'email_scan' : 'extension_scan'
  )
  const [isAskTeamOpen, setIsAskTeamOpen] = useState(false)
  const [isAutoAuditOpen, setIsAutoAuditOpen] = useState(false)
  const askTeam = useAskTeam()
  const [askTeamMessage, setAskTeamMessage] = useState(
    'Hey! Could you help out with identifying which tools we are using?'
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAutoAuditEnabled, setIsAutoAuditEnabled] = useState(false)
  const [frequency, setFrequency] = useState(
    cronToFrequency(organization?.data?.auto_audit_cron)
  )
  const [dayOfWeek, setDayOfWeek] = useState(
    cronToDayOfWeek(organization?.data?.auto_audit_cron)
  )

  const filteredTools = orgVendors?.data?.filter(
    (orgVendor) =>
      orgVendor.source === tab &&
      orgVendor.status === filterStatus &&
      (filterText === '' ||
        orgVendor.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        orgVendor.root_domain?.toLowerCase().includes(filterText.toLowerCase()))
  )

  return (
    <>
      <Column className="gap-4 w-full h-full">
        <Row className="gap-2 items-center mb-1 py-2">
          <Cctv />
          <h1 className="text-2xl ">Detected licences</h1>
        </Row>

        <Card>
          <Row className="mb-4 gap-2 items-center justify-between">
            <Row className="gap-2">
              <Tabs
                defaultValue={tab}
                className="p-0 m-0"
                onValueChange={(value) =>
                  setTab(value as 'email_scan' | 'extension_scan')
                }
              >
                <TabsList>
                  <TabsTrigger value="email_scan" className="h-10 rounded-lg">
                    <Mail className="w-4 h-4 mr-2" />
                    From email (
                    {
                      orgVendors?.data?.filter(
                        (orgVendor) =>
                          orgVendor.source === 'email_scan' &&
                          orgVendor.status === filterStatus
                      )?.length
                    }
                    )
                  </TabsTrigger>

                  <TabsTrigger
                    value="extension_scan"
                    className="h-10 rounded-lg"
                  >
                    <Chrome className="w-4 h-4 mr-2" />
                    From extension (
                    {
                      orgVendors?.data?.filter(
                        (orgVendor) =>
                          orgVendor.source === 'extension_scan' &&
                          orgVendor.status === filterStatus
                      )?.length
                    }
                    )
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </Row>

            <Row className="gap-2">
              <Input
                className="ml-auto"
                placeholder="Filter tools..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />

              <Select
                onValueChange={setFilterStatus}
                defaultValue="not_in_stack"
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Filter by status" />
                </SelectTrigger>

                <SelectContent>
                  <SelectItem value="not_in_stack">
                    Not tracked (
                    {
                      orgVendors?.data?.filter(
                        (vendor) => vendor.status === 'not_in_stack'
                      )?.length
                    }
                    )
                  </SelectItem>

                  <SelectItem value="in_stack">
                    In stack (
                    {
                      orgVendors?.data?.filter(
                        (vendor) => vendor.status === 'in_stack'
                      )?.length
                    }
                    )
                  </SelectItem>

                  <SelectItem value="ignored">
                    Ignored (
                    {
                      orgVendors?.data?.filter(
                        (vendor) => vendor.status === 'ignored'
                      )?.length
                    }
                    )
                  </SelectItem>
                </SelectContent>
              </Select>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button>
                    Actions
                    <CaretDownIcon className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>

                <DropdownMenuContent>
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>
                  <DropdownMenuSeparator />

                  <DropdownMenuItem onClick={() => setIsAskTeamOpen(true)}>
                    Ask team
                    <Mail className="w-4 h-4 ml-2" />
                  </DropdownMenuItem>

                  <DropdownMenuItem onClick={() => setIsAutoAuditOpen(true)}>
                    Auto audit
                    <div className="w-2 h-2 rounded-full bg-gray-500 animate-pulse ml-2" />
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </Row>
          </Row>

          <div className="overflow-auto h-[calc(100vh-235px)]">
            <Table>
              <TableBody>
                {!filteredTools?.length && (
                  <TableRow>
                    <TableCell className="h-24">
                      <Alert className="w-fit mx-auto my-4">
                        Licences will appear from scanning your email, or from
                        the browser extension.
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}

                {filteredTools
                  ?.sort(
                    (a, b) =>
                      new Date(b.created_at).getTime() -
                      new Date(a.created_at).getTime()
                  )
                  .map((orgVendor) => {
                    return (
                      <TableRow key={orgVendor.id}>
                        <TableCell className="py-4">
                          <Row className="flex items-center gap-3">
                            <SaasIcon
                              toolName={orgVendor.name}
                              src={orgVendor.logo_url || ''}
                              size="lg"
                            />
                            <Column>
                              <h2
                                className="text-sm  cursor-pointer"
                                onClick={() =>
                                  window.open(orgVendor.url ?? '', '_blank')
                                }
                              >
                                <Row>
                                  {orgVendor.name}
                                  <ExternalLink className="w-4 h-4 ml-2" />
                                </Row>
                              </h2>

                              <span className="text-xs ">
                                {orgVendor.root_domain}
                              </span>
                            </Column>
                          </Row>
                        </TableCell>

                        <TableCell className="w-[230px]">
                          <Tooltip
                            delayDuration={100}
                            side="right"
                            content={
                              <p className="text-xs  max-w-[300px]">
                                {orgVendor.description}
                              </p>
                            }
                          >
                            <p className="text-xs ">
                              {orgVendor.description
                                ? orgVendor.description.slice(0, 87) +
                                  (orgVendor.description.length > 87
                                    ? '...'
                                    : '')
                                : 'No description avaliable'}
                            </p>
                          </Tooltip>
                        </TableCell>

                        <TableCell>
                          <Column>
                            <span className="text-xs ">
                              {dayjs(orgVendor.created_at).fromNow()}
                            </span>

                            <span className="text-xs ">
                              (
                              {dayjs(orgVendor.created_at).format(
                                'MMM D, YYYY'
                              )}
                              )
                            </span>
                          </Column>
                        </TableCell>

                        <TableCell className="w-[200px]">
                          {orgVendor.status === 'not_set' && (
                            <Badge
                              variant="light"
                              className="text-gray-500 border-gray-500 bg-gray-50/80 rounded-full"
                            >
                              Waiting for response
                            </Badge>
                          )}

                          {orgVendor.status === 'untracked' && (
                            <Badge
                              variant="light"
                              className="text-red-500 border-red-500 bg-red-50/80 rounded-full"
                            >
                              Should not be tracked
                            </Badge>
                          )}

                          {orgVendor.status === 'tracked' && (
                            <Badge
                              variant="light"
                              className="text-green-500 border-green-500 bg-green-50/80 rounded-full"
                            >
                              Should be tracked
                            </Badge>
                          )}
                        </TableCell>

                        {orgVendor.status === 'not_in_stack' && (
                          <TableCell className="text-right">
                            <>
                              <Button
                                className=""
                                variant="ghost"
                                onClick={() => {
                                  updateOrgVendor.mutate({
                                    id: orgVendor.id!,
                                    status: 'ignored',
                                  })
                                }}
                              >
                                Ignore
                              </Button>
                            </>
                          </TableCell>
                        )}

                        <TableCell className="text-right">
                          <Button
                            className=""
                            variant="light"
                            onClick={async () => {
                              await addTool.mutateAsync({
                                name: orgVendor.name,
                                organization_id: orgId,
                                org_vendor_id: orgVendor.id,
                                status: 'in_stack',
                                is_tracking: true,
                                root_domain: orgVendor.root_domain,
                                description: orgVendor.description,
                                owner_org_user_id: orgUser?.data?.id,
                                website: orgVendor.url,
                                type: 'software',
                                department: orgVendor.category,
                                link_to_pricing_page:
                                  orgVendor.link_to_pricing_page,
                              })

                              await updateOrgVendor.mutateAsync({
                                id: orgVendor.id!,
                                status: 'in_stack',
                              })
                            }}
                          >
                            Add to list
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        </Card>
      </Column>

      <Dialog open={isAutoAuditOpen} onOpenChange={setIsAutoAuditOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Auto audit</DialogTitle>

            <DialogDescription className="text-red-500 ">
              This is a demo feature, message us to activate
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4 ">
            <div className="flex flex-row items-center justify-between rounded-lg border p-4 ">
              <Row className="justify-between items-center w-full">
                <Column className="gap-2">
                  <p className="">Toggle auto audit</p>
                  <p className="text-sm text-gray-500">
                    Automatically emails your team to identify which tools they
                    are using, and the costs.
                  </p>
                </Column>

                <Switch
                  checked={false} //={isAutoAuditEnabled}
                  onCheckedChange={setIsAutoAuditEnabled}
                />
              </Row>
            </div>

            <div className="flex flex-row items-center justify-between rounded-lg border p-4 ">
              <Row className="justify-between items-center w-full">
                <Column className="gap-2">
                  <p className="">Schedule</p>
                  <p className="text-sm text-gray-500">
                    How often should we email and ask your team?
                  </p>
                </Column>

                <Row className="gap-2">
                  <Select onValueChange={setFrequency}>
                    <SelectTrigger>
                      <SelectValue placeholder="Weekly" />
                    </SelectTrigger>

                    <SelectContent>
                      <SelectItem value="weekly">Weekly</SelectItem>
                      <SelectItem value="bimonthly">Bi-Monthly</SelectItem>
                      <SelectItem value="monthly">Monthly</SelectItem>
                    </SelectContent>
                  </Select>

                  {frequency === 'weekly' && (
                    <Select onValueChange={setDayOfWeek}>
                      <SelectTrigger>
                        <SelectValue placeholder="Fridays" />
                      </SelectTrigger>

                      <SelectContent>
                        <SelectItem value="monday">Mondays</SelectItem>
                        <SelectItem value="tuesday">Tuesdays</SelectItem>
                        <SelectItem value="wednesday">Wednesdays</SelectItem>
                        <SelectItem value="thursday">Thursdays</SelectItem>
                        <SelectItem value="friday">Fridays</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                </Row>
              </Row>
            </div>

            <p className="text-sm text-gray-500 ml-2">
              {/* {frequency === 'weekly' &&
                    `Your emails will be sent 12:00 every ${
                      // dayOfWeek?.charAt(0).toUpperCase() + dayOfWeek?.slice(1)
                    }`} */}
              {frequency === 'bimonthly' &&
                'Your emails will be sent 12:00 on the 15th and 25th of each month'}
              {frequency === 'monthly' &&
                'Your emails will be sent 12:00 on the 25th of each month'}
              {frequency === 'daily' &&
                'Your emails will be sent 12:00 every weekday'}
            </p>
          </div>

          <DialogFooter>
            <Button
              isLoading={updateOrganization.isLoading}
              onClick={() => {
                const cronExpression = getCronExpression(frequency, dayOfWeek)

                updateOrganization
                  .mutateAsync({
                    id: orgId || '',
                    auto_audit_cron: cronExpression,
                  })
                  .then(() => {
                    setIsAutoAuditOpen(false)
                  })
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isAskTeamOpen} onOpenChange={setIsAskTeamOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Ask your team</DialogTitle>

            <DialogDescription>
              If a person is using a tool, they'll get an email asking them if
              it should be tracked.
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <Textarea
              value={askTeamMessage}
              onChange={(e) => setAskTeamMessage(e.target.value)}
              placeholder="Add a message (optional)"
            />
          </div>

          <DialogFooter>
            <Button variant="light" onClick={() => setIsAskTeamOpen(false)}>
              Cancel
            </Button>

            <Button
              isLoading={askTeam.isLoading}
              onClick={async () =>
                await askTeam
                  .mutateAsync({
                    message: askTeamMessage,
                    organization_id: orgId || '',
                  })
                  .then(() => {
                    setIsAskTeamOpen(false)
                    setAskTeamMessage('')
                  })
              }
            >
              Send question
              <SendHorizonal className="w-4 h-4 ml-2" />
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
