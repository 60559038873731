import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Pencil, Save, Trash2, TriangleAlert, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSendersApi } from 'src/api/db/apiSenders'
import { queryKeys } from 'src/api/db/queryKeys'
import { useReceiptsApi } from 'src/api/db/useReceiptsApi'
import {
  FormDatePicker,
  FormInput,
  FormSelect,
  FormWrapper,
} from 'src/shared/components/FormComponents'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import { z } from 'zod'

import { pricingModelOptions, renewalFrequencyOptions } from './consts'

const formSchema = z.object({
  tool_id: z.number(),
  due_date: z.date(),
  renewal_start_date: z.date(),
  renewal_next_date: z.date(),
  renewal_frequency: z.string(),
  pricing_model: z.string(),
  flat_fee_cost: z.number().optional().or(z.string()), // OR string? huh.
  usage_based_cost: z.number().optional().or(z.string()),
  price_per_seat: z.number().optional().or(z.string()),
  number_of_seats: z.number().optional().or(z.string()),
  other_cost: z.number().optional().or(z.string()),
})

const EmptyField = ({ title, label }: { title: string; label: string }) => (
  <Column>
    <p className="text-xs text-muted-foreground  mt-[3px] mb-[5px]">{title}</p>

    <div className="mb-2 w-full h-[40px] border rounded-md p-2 flex items-center justify-center opacity-70">
      <p className="text-xs text-muted-foreground">{label}</p>
    </div>
  </Column>
)

export const EditReceipt = ({ receipt_id }: { receipt_id: number }) => {
  const [isEditing, setIsEditing] = useState(false)
  const queryClient = useQueryClient()
  const { receiptById, deleteReceipt, updateReceipt } = useReceiptsApi({
    receipt_id,
  })

  const { updateSender } = useSendersApi({
    orgId: receiptById?.sender?.organization_id || '',
  })

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (receiptById?.sender) {
      methods.reset({
        // tool_id: receiptById?.sender?.tool?.id!,
        due_date: receiptById?.due_date
          ? new Date(receiptById.due_date)
          : undefined,
        renewal_start_date: new Date(receiptById.renewal_start_date!),
        renewal_frequency: receiptById.renewal_frequency,
        renewal_next_date: new Date(receiptById.renewal_next_date!),
        pricing_model: receiptById.pricing_model,
        flat_fee_cost: receiptById.flat_fee_cost || 0,
        usage_based_cost: receiptById.usage_based_cost || 0,
        price_per_seat: receiptById.price_per_seat || 0,
        number_of_seats: receiptById.number_of_seats || 0,
        other_cost: receiptById.other_cost || 0,
      })
    }
  }, [receiptById?.sender])

  const handleSubmit = async () => {
    const values = methods.getValues()

    updateSender.mutate({
      id: receiptById?.sender_id!,
      // tool_id: values.tool_id,
    })

    updateReceipt
      .mutateAsync({
        id: receipt_id,
        due_date: values.due_date.toISOString(),
        renewal_frequency: values.renewal_frequency,
        // status: values.status!,
        renewal_start_date: values.renewal_start_date.toISOString(),
        renewal_next_date: values.renewal_next_date.toISOString(),
        pricing_model: values.pricing_model,
        flat_fee_cost: Number(values.flat_fee_cost),
        usage_based_cost: Number(values.usage_based_cost),
        price_per_seat: Number(values.price_per_seat),
        number_of_seats: Number(values.number_of_seats),
        other_cost: Number(values.other_cost),
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [queryKeys.receipts] })
      })
  }

  const FormEdit = () => (
    <FormWrapper
      onSubmit={methods.handleSubmit(handleSubmit)}
      methods={methods}
    >
      <Column className="gap-3">
        <Card>
          <Row className="gap-2">
            <div className="w-[50%]">
              {methods.getValues('renewal_frequency') !== 'OTHER' ? (
                <FormSelect
                  label="Renewal frequency"
                  name="renewal_frequency"
                  options={renewalFrequencyOptions}
                  methods={methods}
                />
              ) : (
                <EmptyField
                  title="Renewal frequency"
                  label="No renewal frequency"
                />
              )}
            </div>

            <div className="w-[50%]">
              {methods.getValues('due_date') ? (
                <FormDatePicker
                  name="due_date"
                  methods={methods}
                  label="Due date"
                  fullWidth
                />
              ) : (
                <EmptyField title="Due date" label="No due date" />
              )}
            </div>
          </Row>

          <Row className="gap-2">
            <FormDatePicker
              name="renewal_start_date"
              label="Invoice period start"
              methods={methods}
              fullWidth
            />

            <FormDatePicker
              name="renewal_next_date"
              methods={methods}
              label="Invoice period end"
              fullWidth
            />
          </Row>

          <FormSelect
            name="pricing_model"
            options={pricingModelOptions}
            methods={methods}
            label="Pricing model"
          />

          {methods.watch('pricing_model') === 'FLAT_FEE' && (
            <FormInput
              label="Cost ($)"
              name="flat_fee_cost"
              methods={methods}
              className="w-full"
              type="number"
            />
          )}

          {methods.watch('pricing_model') === 'PER_SEAT' && (
            <Row className="gap-3">
              <FormInput
                name="number_of_seats"
                label="Number of seats"
                type="number"
                methods={methods}
                className="w-full"
              />

              <FormInput
                name="price_per_seat"
                label="Price per seat ($)"
                methods={methods}
                className="w-full"
                type="number"
              />
            </Row>
          )}

          {methods.watch('pricing_model') === 'USAGE_BASED' && (
            <FormInput
              name="usage_based_cost"
              label="Usage cost ($)"
              type="number"
              methods={methods}
              className="w-full"
            />
          )}

          {methods.watch('pricing_model') === 'OTHER' && (
            <FormInput
              name="other_cost"
              label="Other cost ($)"
              type="number"
              methods={methods}
              className="w-full"
            />
          )}
        </Card>
      </Column>
    </FormWrapper>
  )

  const SeeInfo = () => (
    <Card>
      {receiptById?.warning_info && (
        <Card>
          <Row className="gap-2 items-center">
            <TriangleAlert className="w-6 h-6 text-orange-500" />
            <p className="text-sm">{receiptById?.warning_info}</p>
          </Row>
        </Card>
      )}

      <p className="text-sm">
        This service is provided by{' '}
        <span className="font-bold">{receiptById?.sender?.name}</span>, and
        renews{' '}
        <span className="font-bold">{receiptById?.renewal_frequency}</span>. The
        cost is{' '}
        <span className="font-bold">
          {receiptById?.total_cost} {receiptById?.currency}
        </span>
        .
        <br />
        <br />
        It is probably renewed on{' '}
        <span className="font-bold">
          {dayjs(receiptById?.renewal_next_date).format('YYYY-MM-DD')}
        </span>
        . The due date is{' '}
        <span className="font-bold">
          {dayjs(receiptById?.due_date).format('YYYY-MM-DD')}
        </span>
        .
        <br />
        <br />
        {receiptById?.bank_number &&
          receiptById?.ocr &&
          receiptById?.due_date && (
            <p className="text-sm">
              Bank number: {receiptById?.bank_number}
              <br />
              OCR: {receiptById?.ocr}
              <br />
              Due date:{' '}
              {dayjs(receiptById?.due_date).format('YYYY-MM-DD') + ' '}
              (in {dayjs(receiptById?.due_date).diff(dayjs(), 'days')} days)
            </p>
          )}
      </p>
    </Card>
  )

  return (
    <Column className="h-fit col-span-1">
      {isEditing ? <FormEdit /> : <SeeInfo />}

      <Row className="gap-2 mt-3">
        {isEditing ? (
          <Button
            variant="transparent"
            className="ml-auto"
            onClick={() => setIsEditing(!isEditing)}
          >
            <X className="w-4 h-4 mr-2" />
            Cancel
          </Button>
        ) : (
          <Button
            variant="transparent"
            className="ml-auto"
            onClick={() => setIsEditing(!isEditing)}
          >
            <Pencil className="w-4 h-4 mr-2" />
            Edit
          </Button>
        )}

        <Button
          variant="transparent"
          className="text-red-500"
          onClick={() =>
            deleteReceipt.mutate({
              id: receipt_id,
            })
          }
        >
          <Trash2 className="w-4 h-4 mr-2" />
          Delete
        </Button>

        {isEditing && (
          <Button className="ml-2" type="submit" onClick={handleSubmit}>
            <Save className="w-4 h-4 mr-2" />
            Save
          </Button>
        )}
      </Row>
    </Column>
  )
}
