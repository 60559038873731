import { Pencil } from 'lucide-react'
import { Button } from 'src/shared/components/ui/button'
import { Card } from 'src/shared/components/ui/card'

// import { Save } from 'lucide-react'
// import { Column, Row } from 'src/shared/components/Semantic/all'
// import { Input } from 'src/shared/components/ui'
// import { Badge } from 'src/shared/components/ui/badge'
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableRow,
// } from 'src/shared/components/ui/table'

export function Billing() {
  return (
    <Card>
      <Button
        disabled={true}
        variant={'transparent'}
        className="w-fit"
        onClick={() =>
          window.open(
            'https://billing.stripe.com/p/login/aEU9BB8TTcLge1a3cc',
            '_blank'
          )
        }
      >
        <Pencil className="h-3 w-3 mr-2" />
        Manage subscription
      </Button>

      <p className="text-xs text-muted-foreground mt-2">
        PinnOne is free to use.
      </p>

      {/* <Column className="gap-3">
        <Row className="gap-3">
          <div className="w-fit p-1 bg-gradient-to-r from-blue-600/80 to-blue-800/80 rounded-md">
            <p className="text-xs font-medium ml-2 my-2">PinnOne Receipts</p>

            <Table className="w-[500px] text-xs font-normal">
              <TableBody>
                <TableRow>
                  <TableCell className="p-2 text-left">
                    Included email scans used
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      17/30
                    </Badge>
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      $0
                    </Badge>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="p-2 text-left">
                    Additional email scans used
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      0
                    </Badge>
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      $0
                    </Badge>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Row className="gap-2 p-2 items-center w-full">
              <p className="text-xs w-full">Additional scan monthly limit</p>
              <Input
                className="w-full bg-transparent"
                type="number"
                value={12}
                onChange={(e) => setDaysLeft(Number(e.target.value))}
              />
              <Save className="w-10 h-10" />
            </Row>
          </div>

          <div className="w-fit p-1 bg-gradient-to-r from-orange-500/80 to-orange-800/80 rounded-md">
            <p className="text-xs font-medium ml-2 my-2">
              PinnOne Software Manager
            </p>

            <Table className="w-[500px] text-xs font-normal">
              <TableBody>
                <TableRow>
                  <TableCell className="p-2 text-left">
                    Included extension users
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      1/2
                    </Badge>
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      $0
                    </Badge>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="p-2 text-left">
                    Additional extension users
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      0
                    </Badge>
                  </TableCell>

                  <TableCell className="p-2 text-right">
                    <Badge variant="light" className="font-medium">
                      $0
                    </Badge>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Row>

        <Card className="w-fit p-1">
          <Table className="w-[500px] text-xs">
            <TableBody>
              <TableRow className="">
                <TableCell className="p-2 text-left">
                  <p className="text-xs font-medium bg-gradient-to-r from-blue-500 to-blue-700 bg-clip-text text-transparent">
                    PinnOne Receipts
                  </p>
                </TableCell>

                <TableCell className="p-2 text-right"></TableCell>

                <TableCell className="p-2 text-right">
                  <Badge variant="light" className="font-semibold">
                    $0
                  </Badge>
                </TableCell>
              </TableRow>

              <TableRow className="">
                <TableCell className="p-2 text-left">
                  <p className="text-xs font-medium bg-gradient-to-r from-orange-400 to-orange-800 bg-clip-text text-transparent">
                    PinnOne Software Manager
                  </p>
                </TableCell>

                <TableCell className="p-2 text-right"></TableCell>

                <TableCell className="p-2 text-right">
                  <Badge variant="light" className="font-semibold">
                    $0
                  </Badge>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className="p-2 text-left font-medium">
                  Fixed cost
                </TableCell>

                <TableCell className="p-2 text-right"></TableCell>

                <TableCell className="p-2 text-right">
                  <Badge variant="light" className="font-medium">
                    $40
                  </Badge>
                </TableCell>
              </TableRow>

              <TableRow className="font-medium">
                <TableCell className="p-2 text-left">
                  Total for February 2025
                </TableCell>

                <TableCell className="p-2 text-right"></TableCell>

                <TableCell className="p-2 text-right">
                  <Badge variant="light" className="font-semibold">
                    $40
                  </Badge>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Column> */}
    </Card>
  )
}
