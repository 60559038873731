import * as React from 'react'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/shared/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from 'src/shared/components/ui/chart'

import { useChartData } from './consts'

const chartConfig = {
  visitors: {
    label: 'Visitors',
  },
  cost: {
    label: 'Cost',
    color: 'hsl(var(--chart-6))',
  },
} satisfies ChartConfig

export const BarChartComponent = () => {
  const chartData = useChartData()

  // Group data by month and sum costs
  const monthlyData = chartData.reduce(
    (acc, item) => {
      const date = new Date(item.date)
      const monthKey = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, '0')}`

      if (!acc[monthKey]) {
        acc[monthKey] = {
          date: monthKey,
          cost: 0,
        }
      }
      acc[monthKey].cost += item.cost
      return acc
    },
    {} as Record<string, { date: string; cost: number }>
  )

  // Convert to array and sort by date
  const monthlyChartData = Object.values(monthlyData)
    .sort((a, b) => a.date.localeCompare(b.date))
    .slice(-3) // Show last 3 months

  return (
    <Card>
      <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
        <div className="grid flex-1 gap-1 text-center sm:text-left">
          <CardTitle>Cost per month</CardTitle>
          <CardDescription>Monthly cost breakdown</CardDescription>
        </div>
      </CardHeader>

      <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <BarChart data={monthlyChartData}>
            <CartesianGrid vertical={false} />

            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => {
                const [year, month] = value.split('-')
                return new Date(
                  parseInt(year),
                  parseInt(month) - 1
                ).toLocaleDateString('en-US', {
                  month: 'long',
                })
              }}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `$${value}`}
            />

            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  labelFormatter={(value) => {
                    const [year, month] = value.split('-')
                    return new Date(
                      parseInt(year),
                      parseInt(month) - 1
                    ).toLocaleDateString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })
                  }}
                  indicator="dot"
                />
              }
            />

            <Bar
              dataKey="cost"
              fill="var(--color-cost)"
              radius={[4, 4, 0, 0]}
              label={{
                position: 'top',
                formatter: (value) =>
                  value > 0 ? `$${Math.round(value)}` : '',
                fontSize: 12,
                fill: 'var(--color-cost)',
              }}
            />

            <ChartLegend content={<ChartLegendContent />} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
