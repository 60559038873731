import { Column } from 'src/shared/components/Semantic/all'
import { Row } from 'src/shared/components/Semantic/all'
import { Switch } from 'src/shared/components/ui/switch'

export const SettingsRow = ({
  title,
  description,
  content,
  onChange,
  checked,
  disabled,
}: {
  title: React.ReactNode
  description: React.ReactNode
  content?: React.ReactNode
  onChange: (value: boolean) => void
  checked: boolean
  disabled?: boolean
}) => {
  return (
    <Row className="justify-between items-center border p-4 rounded-md bg-white/5">
      <Column className="gap-2">
        <p className="text-sm font-medium">{title}</p>
        <p className="text-xs text-gray-500">{description}</p>

        {content}
      </Column>

      <Switch
        checked={checked}
        onCheckedChange={onChange}
        disabled={disabled}
      />
    </Row>
  )
}
