import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from './utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-lg border px-2 py-0.5 text-xs  text-white/85 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-theme_light', //hover:bg-primary/80
        secondary: 'bg-theme_light',
        light: 'border-transparent',
        destructive: 'border-transparent',
        outline: 'text-foreground',
        white: ' text-foreground border-white',
        blue: 'bg-gradient-to-r from-blue-400 to-blue-600 font-normal',
        yellow:
          'border border-yellow-300/80 bg-gradient-to-r from-yellow-300 to-yellow-500 text-black font-normal',
        orange: 'bg-gradient-to-r from-orange-400 to-orange-600 font-normal',
        green: 'bg-gradient-to-r from-green-400 to-green-600 font-normal',
        red: 'bg-gradient-to-r from-red-400 to-red-600 font-normal',
        purple: 'bg-gradient-to-r from-purple-400 to-purple-600 font-normal',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
