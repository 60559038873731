import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Banknote, ExternalLink, Users } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useToolsApi } from 'src/api/db/apiTools'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import { departmentOptions } from 'src/shared/consts'

import { StatCard } from '../../../Software_Insights/components/StatCard'
import { CostCard } from '../Costs/CostCard'
import { UsersBarChart } from './UsersBarChart'

dayjs.extend(relativeTime)

export const Home = () => {
  const navigate = useNavigate()
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools, updateTool } = useToolsApi({ organization_id: orgId || '' })
  const { orgUsers } = useOrgUsersApi()
  const tool = tools?.tools?.find((tool) => tool.id === Number(tool_id))

  const budgetOwner = useMemo(() => tool?.budget_owner.id, [tool?.budget_owner])
  const department = useMemo(() => tool?.department, [tool?.department])

  const SelectBudgetOwner = () => {
    return (
      <Select
        value={budgetOwner?.toString() || '-'}
        onValueChange={(value) => {
          updateTool.mutate({
            id: tool?.id!,
            owner_org_user_id: value === '-' ? null : Number(value),
          })
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Budget owner" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem key="-" value="-">
            No budget owner
          </SelectItem>

          {orgUsers?.data?.map((user) => (
            <SelectItem key={user.id} value={user.id.toString()}>
              {user.user?.email?.slice(0, 12) + '...'}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }

  const SelectDepartment = () => {
    return (
      <Select
        onValueChange={(value) => {
          updateTool.mutate({
            id: tool?.id!,
            department: value === '-' ? null : value,
          })
        }}
        defaultValue={department || '-'}
      >
        <SelectTrigger>
          <SelectValue placeholder="No department" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem key="-" value="-">
            No department
          </SelectItem>

          {departmentOptions.map((department) => (
            <SelectItem key={department.value} value={department.value || ''}>
              {department.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }

  return (
    <Column className="gap-4 w-full">
      <div className="grid grid-cols-3 w-full gap-4">
        <Column className="gap-4 col-span-2">
          <Row className="gap-4">
            <Card>
              <Row className="gap-4">
                <Column className="gap-2 min-w-[140px]">
                  <p className="text-sm  text-muted-foreground">Budget owner</p>

                  {SelectBudgetOwner()}
                </Column>

                <Column className="gap-2 min-w-[140px]">
                  <p className="text-sm  text-muted-foreground">Department</p>

                  {SelectDepartment()}
                </Column>
              </Row>
            </Card>

            <StatCard
              title="Active users"
              value={tool?.calculated?.userCount || 0}
              icon={<Users className="w-6 h-6 text-muted-foreground" />}
              description="This month"
            />

            <StatCard
              title="Total spend"
              value={'$' + tool?.calculated?.priceAnnual || 0}
              icon={<Banknote className="w-6 h-6 text-muted-foreground" />}
              description="Per year" // Annualized
            />
          </Row>

          <UsersBarChart />
        </Column>

        <Column className="gap-4 col-span-1">
          <Card>
            <Row className="justify-between items-center">
              <p className="text-lg ">Costs</p>

              <Button variant="light" onClick={() => navigate('#costs')}>
                See cost
              </Button>
            </Row>

            <CostCard />
          </Card>

          <Card>
            <p className="text-lg ">About {tool?.name || 'About Tool'}</p>
            <p className="text-sm text-muted-foreground mt-1">
              {tool?.description || 'No description available'}
            </p>

            {tool?.is_tracking && (
              <Column className="mt-2">
                <Badge className="px-2 py-1 mt-2 w-fit border rounded-full">
                  <div className="mr-2 w-2 h-2 bg-green-500 rounded-full"></div>
                  Tracking activity of {tool?.root_domain}
                </Badge>

                <p className="text-[13px] text-gray-500 mt-1 ml-2">
                  For example:
                  <br />- https://{tool?.root_domain}
                  <br />- https://{tool?.root_domain}/dashboard
                </p>
              </Column>
            )}

            <Row>
              <Button
                variant="light"
                className="mt-4"
                onClick={() => window.open(tool?.website || '', '_blank')}
              >
                <ExternalLink className="w-4 h-4 mr-2" />
                Website
              </Button>

              <Button
                variant="light"
                className="mt-4 ml-2"
                onClick={() =>
                  window.open(tool?.link_to_pricing_page || '', '_blank')
                }
              >
                <ExternalLink className="w-4 h-4 mr-2" />
                Pricing
              </Button>
            </Row>
          </Card>
        </Column>
      </div>
    </Column>
  )
}
