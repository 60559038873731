import dayjs from 'dayjs'
import {
  Coins,
  Computer,
  CreditCard,
  HelpingHand,
  PersonStanding,
} from 'lucide-react'
import { useState } from 'react'
import { useOrgUsersApi } from 'src/api/db/apiOrgUsers'
import { useSendReceipts } from 'src/api/server/useSendReceipts'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Dialog,
  DialogContent,
  Image,
  Input,
  ScrollArea,
} from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'src/shared/components/ui/dialog'
import { Tabs, TabsList, TabsTrigger } from 'src/shared/components/ui/tabs'

export const SendReceiptsDialog = ({
  selectedRows,
  emailDialogOpen,
  setEmailDialogOpen,
}: {
  selectedRows: any[]
  emailDialogOpen: boolean
  setEmailDialogOpen: (open: boolean) => void
}) => {
  const sendReceipts = useSendReceipts()
  const { orgUser } = useOrgUsersApi()

  const [sendType, setSendType] = useState('to_accountant')
  const [emailTo, setEmailTo] = useState(
    () => localStorage.getItem('emailTo') || ''
  )
  const filteredReceipts = selectedRows.map((row) => row.original.receipt)

  const handleSendEmails = () => {
    sendReceipts.mutate({
      sendType,
      fromEmail: emailTo,
      toEmail: emailTo,
      fileUrls: filteredReceipts
        ?.map((receipt) => receipt.receipt_file || '')
        .filter(Boolean) as string[],
      organization_id: orgUser?.data?.organization_id!,
      org_user_id: orgUser?.data?.id!,
    })

    localStorage.setItem('emailTo', emailTo)
    setEmailDialogOpen(false)
  }

  return (
    <Dialog open={emailDialogOpen} onOpenChange={setEmailDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Send Receipts</DialogTitle>

          <DialogDescription>
            Send the to an accountant or a system.
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="w-full h-[300px] mt-2">
          <Column className="w-full h-full">
            <div className="grid grid-cols-2 gap-2 w-full">
              {filteredReceipts?.map((receipt) => (
                <div key={receipt.id} className="p-0 rounded-lg relative">
                  <Column className="p-2 h-[100%] gap-[5px] absolute top-0 right-0 bg-gradient-to-b from-black/50 to-black/10 w-full rounded-lg">
                    <Badge variant="secondary" className="w-fit rounded-full">
                      <HelpingHand className="w-4 h-4 mr-2" />
                      {receipt.sender?.name}
                    </Badge>

                    <Badge variant="secondary" className="w-fit rounded-full">
                      <Coins className="w-4 h-4 mr-2" />
                      {`${receipt.total_cost} ${receipt.currency}`}
                    </Badge>

                    {receipt.due_date && (
                      <Badge variant="secondary" className="w-fit rounded-full">
                        <CreditCard className="w-4 h-4 mr-2" />
                        Due {dayjs(receipt.due_date).fromNow()}
                      </Badge>
                    )}

                    {receipt.type === 'software' && (
                      <Badge variant="secondary" className="w-fit rounded-full">
                        <CreditCard className="w-4 h-4 mr-2" />
                        Software
                      </Badge>
                    )}
                  </Column>

                  <Image
                    src={receipt.receipt_file || ''}
                    alt="receipt"
                    width={200}
                    className="w-full h-[170px] object-cover rounded-lg"
                  />
                </div>
              ))}
            </div>
          </Column>
        </ScrollArea>

        <Input
          className="mt-3"
          placeholder="Email address"
          value={emailTo}
          onChange={(e) => setEmailTo(e.target.value)}
        />

        <Row className="gap-2 justify-end mt-1">
          <Tabs
            defaultValue={sendType}
            onValueChange={setSendType}
            className="mr-auto"
          >
            <TabsList>
              <TabsTrigger value="to_accountant" className="rounded-full">
                <PersonStanding className="w-4 h-4 mr-2" />
                To accountant
              </TabsTrigger>

              <TabsTrigger value="to_system" className="rounded-full">
                <Computer className="w-4 h-4 mr-2" />
                To system
              </TabsTrigger>
            </TabsList>

            <p className="text-xs text-gray-500 ml-2 mt-1">
              {sendType === 'to_accountant'
                ? 'Send as a ZIP file.'
                : 'Send each receipt as a separate mail.'}
            </p>
          </Tabs>

          <Button
            variant="secondary"
            onClick={() => setEmailDialogOpen(false)}
            className="mt-1"
          >
            Cancel
          </Button>

          <Button onClick={handleSendEmails} className="mt-1">
            Send {filteredReceipts?.length} receipts
          </Button>
        </Row>
      </DialogContent>
    </Dialog>
  )
}
