import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { LoaderPinwheel } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNotifications } from 'src/api/db/apiNotifications'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { queryKeys } from 'src/api/db/queryKeys'
import { useSupabaseClient } from 'src/api/supabase'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import { Dialog, DialogContent } from 'src/shared/components/ui'
import { useToast } from 'src/shared/hooks/use-toast'

import { cn } from '../ui/utils'
import { Activities } from './Activities'

export const SmartPanel = () => {
  const [open, setOpen] = useState(() => {
    const stored = localStorage.getItem('smartPanelOpen')
    return stored ? JSON.parse(stored) : true
  })

  useEffect(() => {
    localStorage.setItem('smartPanelOpen', JSON.stringify(open))
  }, [open])

  const { toast } = useToast()
  const { notifications } = useNotifications()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const activities =
    notifications?.data
      ?.filter(({ tag }) => tag?.includes('activity'))
      .slice(0, 10) || []
  const emails =
    notifications?.data
      ?.filter(({ tag }) => tag?.includes('email'))
      .slice(0, 10) || []

  useEffect(() => {
    let loading1 = false
    let loading2 = false

    if (activities.length) {
      loading1 = !activities?.[0]?.tag?.includes('finished')
    }
    if (emails.length) {
      loading2 = !emails?.[0]?.tag?.includes('finished')
    }
    queryClient.invalidateQueries({ queryKey: [queryKeys.receipts] })

    setLoading(Boolean(loading1 || loading2))
  }, [notifications?.data])

  const supabase = useSupabaseClient()
  const { orgId } = useOrgIdApi()

  useEffect(() => {
    const changes = supabase
      .channel('notifications-realtime')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'notification',
          filter: `organization_id=eq.${orgId}`,
        },
        (payload: any) => {
          queryClient.invalidateQueries({ queryKey: [queryKeys.notifications] })
          const { created_at, dataObject, title } = payload.new

          toast({
            itemID: created_at,
            delay: 6000,
            variant: 'success',
            // @ts-ignore
            title: <p className="text-xs  text-muted-foreground">{title}</p>,
            description: (
              <Column className="gap-2">
                <Row className="justify-between items-center gap-2">
                  <p className="text-muted-foreground text-xs">
                    {dayjs(created_at).fromNow()}
                  </p>
                </Row>

                {dataObject && (
                  <Row className="gap-2 ">
                    <p className="text-xs text-muted-foreground">
                      {dataObject}
                    </p>
                  </Row>
                )}
              </Column>
            ),
          })
        }
      )
      .subscribe()
    return () => {
      changes.unsubscribe()
    }
  }, [orgId])

  const SmartPanelButton = () => (
    <Column className="absolute bottom-4 right-4 gap-2">
      <Button
        onClick={() => setOpen(true)}
        variant="outline"
        className={cn('w-fit ml-auto')}
      >
        <LoaderPinwheel
          className={cn(
            'w-4 h-4 mr-2',
            loading && 'animate-[spin_7s_linear_infinite] text-orange-500'
          )}
        />
        {loading ? (
          <p className="bg-gradient-to-r from-orange-500 to-orange-300 bg-clip-text text-transparent animate-pulse">
            Working...
          </p>
        ) : (
          <p>Smart Panel</p>
        )}
      </Button>
    </Column>
  )

  return (
    <div>
      <SmartPanelButton />

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent
          showClose={false}
          className="rounded-[30px] p-3 h-[500px]"
        >
          <Activities />
        </DialogContent>
      </Dialog>
    </div>
  )
}
