export const paths = {
  onboarding: '/onboarding',
  loginSignUp: '/login-signup',

  extension: '/extension',
  validateDomains: '/validate-domains',
  cookieConsent: '/cookieConsent',
  privacyPolicy: '/privacyPolicy',
  termsOfService: '/termsOfService',
  requestFeature: '/request-feature',
  receipts: '/receipts',
  costs: '/costs',
  usage: '/usage',

  software_insights: '/software/insights',
  software_table: '/software/table',
  software_untracked: '/software/untracked',

  userSettings: '/user-settings',
  organization: '/organization',
}
