import { zodResolver } from '@hookform/resolvers/zod'
import { LogIn, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSupabaseClient } from 'src/api/supabase'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card, Image } from 'src/shared/components/ui'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { useToast } from 'src/shared/hooks/use-toast'
import { z } from 'zod'

import logo from './logo.svg'

const formSchema = z.object({
  email: z.string().email(),
  team_name: z.string().min(1),
})

export const LoginSignup = () => {
  const [loading, setLoading] = useState(false)
  const supabaseClient = useSupabaseClient()
  const [success, setSuccess] = useState(false)
  const { toast } = useToast()
  const { plan } = useParams()
  const navigate = useNavigate()
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(plan)

  useEffect(() => {
    if (plan !== selectedPlan) {
      navigate(`${paths.loginSignUp}/${selectedPlan || ''}`, { replace: true })
    }
  }, [selectedPlan])

  useEffect(() => {
    if (plan !== selectedPlan) {
      setSelectedPlan(plan)
    }
  }, [plan])

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      email: '',
      team_name: '',
    },
    resolver: zodResolver(formSchema),
  })

  const onError = (error) => {
    const msg = JSON.stringify(error.message).slice(0, 250)
    const message = msg.includes('Signups not allowed for otp')
      ? 'You need to create a user'
      : msg

    toast({
      variant: 'destructive',
      // @ts-ignore
      title: (
        <Row className="flex items-center gap-1">
          <X className="h-4 w-4" />
          {message || 'Something went wrong'}
        </Row>
      ),
    })
  }

  const signUp = async () => {
    setLoading(true)

    const { data: existingUser } = await supabaseClient
      .from('user')
      .select()
      .eq('email', form.getValues('email').toLowerCase())
      .single()

    if (existingUser) {
      toast({
        title: 'Something went wrong',
        description: 'User already exists. Please login.',
        variant: 'destructive',
        duration: 5000,
      })
      setLoading(false)
      return
    }

    const { error } = await supabaseClient.auth.signInWithOtp({
      email: form.getValues('email').toLowerCase(),
      options: {
        emailRedirectTo: process.env.REACT_APP_FE_SERVER_URL,
        data: {
          team_name: form.getValues('team_name'),
          role: 'superadmin',
          stripe_product_id: selectedPlan,
        },
      },
    })

    if (error) {
      onError(error)
    } else {
      setSuccess(true)
    }
    setLoading(false)
  }

  const login = async () => {
    setLoading(true)

    // if (!existingUser) {
    //   toast({
    //     title: 'Something went wrong',
    //     description: 'User not found. Please sign up first.',
    //     variant: 'destructive',
    //     duration: 5000,
    //   })
    //   setLoading(false)
    //   return
    // }

    const data = await supabaseClient.auth.signInWithOtp({
      email: form.getValues('email').toLowerCase(),
      options: {
        emailRedirectTo: process.env.REACT_APP_FE_SERVER_URL,
        shouldCreateUser: false,
      },
    })

    if (data.error) {
      onError(data.error)
    } else {
      setSuccess(true)
    }
    setLoading(false)
  }

  if (success) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center bg-theme_light">
        <Card>
          <Column className="gap-1">
            <h2 className="text-md ">Success! 🎉</h2>
            <p className="text-sm text-muted-foreground">
              To enter, click the link in your email.
            </p>
          </Column>
        </Card>
      </div>
    )
  }

  const Signup = () => (
    <TabsContent value="signup">
      <Column className="gap-2 mt-4">
        <Input
          label="Email"
          placeholder="name@example.com"
          autoComplete="email"
          style={{ flex: 1 }}
          {...form.register('email')}
        />

        <Input
          label="Org. name"
          placeholder="My Company"
          style={{ flex: 1 }}
          {...form.register('team_name')}
        />

        <Button
          className="mt-2"
          isLoading={loading}
          onClick={signUp}
          disabled={!form.watch('team_name')}
        >
          Sign up for free
          <LogIn className="h-4 w-4 ml-2" />
        </Button>
      </Column>
    </TabsContent>
  )

  const Login = () => (
    <TabsContent value="login">
      <Column className="gap-4">
        <Input
          label="Email"
          autoComplete="email"
          style={{ flex: 1 }}
          {...form.register('email')}
          placeholder="Your email"
          // onKeyDown={getHotkeyHandler([['Enter', login]])}
        />

        <Button
          isLoading={loading}
          onClick={login}
          disabled={!form.watch('email')}
        >
          Enter
          <LogIn className="h-4 w-4 ml-2" />
        </Button>
      </Column>
    </TabsContent>
  )

  return (
    <div className="w-full h-[100vh] flex items-center justify-center relative bg-theme_light">
      <Column className="gap-4">
        <Image src={logo} alt="logo" className="h-[30px] mb-2" />

        <Card className="m-auto grid gap-6 w-[350px] p-6">
          <Tabs defaultValue="signup">
            <TabsList className="w-fit mx-auto">
              <TabsTrigger value="signup" className="font-medium">
                Signup
              </TabsTrigger>

              <TabsTrigger value="login" className="font-medium">
                Login
              </TabsTrigger>
            </TabsList>

            {Signup()}
            {Login()}
          </Tabs>
        </Card>

        <Row className="gap-1 mx-auto">
          <Link
            className="text-xs text-muted-foreground"
            to={paths.cookieConsent}
          >
            Cookie Policy
          </Link>
          <p className="text-xs text-muted-foreground">|</p>
          <Link
            className="text-xs text-muted-foreground"
            to={paths.privacyPolicy}
          >
            Privacy Policy
          </Link>
          <p className="text-xs text-muted-foreground">|</p>
          <Link
            className="text-xs text-muted-foreground"
            to={paths.termsOfService}
          >
            Terms of Service
          </Link>
        </Row>
      </Column>
    </div>
  )
}
