import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { OrgUser_, User_ } from '../apiOrgUsers'
import { OrgVendor_ } from '../apiOrgVendors'
import { Sender_ } from '../apiSenders'
import { Vendor_ } from '../apiVendors'
import { queryKeys } from '../queryKeys'
import { calculateData } from './utils/calculateCostData'
import { calculateDataTotal } from './utils/calculateCostDataTotal'

dayjs.extend(relativeTime)

export type InsertTool = Database['public']['Tables']['tool']['Insert']
export type UpdateTool = Database['public']['Tables']['tool']['Update']

export type Tool_ = Database['public']['Tables']['tool']['Row'] & {
  org_vendor: OrgVendor_
  budget_owner: OrgUser_ & {
    user: User_
  }
  vendor: Vendor_
  sender: Sender_

  calculated: {
    priceMonth: number
    priceQuarter: number
    priceAnnual: number

    priceMonthFormatted: string
    priceQuarterFormatted: string
    priceAnnualFormatted: string

    pricingModel: any
    renewsIn: number
    nextRenewalDate: string
    startsAt: string | null
    cancelledAt: string | null

    orgUserIds: number[]
    userCount: number
    monthlyUserCount: {
      label: string
      value: number
    }[]
    monthlyUserVisits: number
  }
}

export const useToolsApi = ({
  organization_id,
  org_user_id,
}: {
  organization_id?: string
  org_user_id?: number
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { data: tools } = useQuery({
    queryKey: [queryKeys.tools, organization_id],
    queryFn: async () => {
      const tools_ = await supabase
        .from('tool')
        .select(
          `*,
          budget_owner:org_user(*, user!org_user_user_id_fkey(*)),
          user_activity(*),
          vendor(*),
          org_vendor(*),
          sender(*, receipt(*))
          `
        )
        .eq('organization_id', organization_id!)

      const enrichedTools = tools_.data?.map((tool) => {
        const calculated = calculateData({
          receipts: tool.sender?.receipt as any,
          user_activity: tool.user_activity as any,
        })

        const enrichedTool: any = {
          ...tool,
          calculated,
        }
        return enrichedTool as Tool_
      })

      return {
        tools: enrichedTools,
        calculated: calculateDataTotal(enrichedTools as Tool_[]),
      }
    },
    enabled: !!organization_id,
  })

  const updateTool = useMutation({
    mutationFn: async (updateData: UpdateTool) =>
      await supabase.from('tool').update(updateData).eq('id', updateData.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })

      toast({
        variant: 'success',
        title: 'Updated tool',
      })
    },
  })

  const addTool = useMutation({
    mutationFn: async (tool: InsertTool) =>
      await supabase.from('tool').insert(tool),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })
      queryClient.invalidateQueries({ queryKey: [queryKeys.toolsAll] })

      toast({
        variant: 'success',
        title: 'Licence added',
      })
    },
  })

  const upsertTools = useMutation({
    mutationFn: async (tools: InsertTool[]) =>
      await supabase.from('tool').upsert(tools, {
        onConflict: 'vendor_id',
        ignoreDuplicates: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })

      toast({
        variant: 'success',
        title: 'Tools added. Some tools might be generating in the background.',
      })
    },
  })

  const deleteTool = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      await supabase.from('tool').delete().eq('id', id).select('vendor_id')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })
      queryClient.invalidateQueries({ queryKey: [queryKeys.toolsAll] })
    },
  })

  const userTools = useQuery({
    queryKey: [queryKeys.userTools, org_user_id],
    queryFn: async () => {
      const tools_ = await supabase
        .from('user_activity')
        .select('id, tool(*, vendor(*), subscription(*))')
        .eq('org_user_id', org_user_id!)
        .eq('tool.status', 'not_in_stack')

      return tools_.data
    },
    enabled: !!org_user_id,
  })

  return {
    tools,
    updateTool,
    deleteTool,
    addTool,
    upsertTools,
    userTools,
  }
}
