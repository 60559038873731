import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Pencil } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useReceiptsApi } from 'src/api/db/useReceiptsApi'
import { Receipt } from 'src/pages/Receipts/components/Receipt'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, ScrollArea } from 'src/shared/components/ui'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/shared/components/ui/accordion'

dayjs.extend(relativeTime)

export const Costs = () => {
  const { tool_id } = useParams()
  const { receiptsByTool } = useReceiptsApi({ tool_id })
  const navigate = useNavigate()

  return (
    <>
      <Card>
        <Row className="justify-between items-center">
          <h2 className="text-lg ">Cost data</h2>

          <Button
            variant="light"
            className="mt-4"
            onClick={() => navigate('#settings')}
          >
            <Pencil className="mr-2 w-4 h-4" />
            Cost settings
          </Button>
        </Row>

        <ScrollArea className="h-[426px] mt-4" scrollbarHide>
          <Column className="gap-2">
            {receiptsByTool?.data?.map((receipt) => (
              <Accordion type="single" collapsible key={receipt.id}>
                <AccordionItem value={receipt.id.toString()}>
                  <AccordionTrigger className="text-sm bg-white/50 rounded-md p-2 border">
                    Receipt from {receipt.sender?.name}
                  </AccordionTrigger>

                  <AccordionContent>
                    <Receipt receipt={receipt as any} />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
          </Column>
        </ScrollArea>
      </Card>
    </>
  )
}
