import { Tool_ } from 'src/api/db/apiTools'
import { Receipt_ } from 'src/api/db/useReceiptsApi'
import { Row } from 'src/shared/components/Semantic/all'
import { Image } from 'src/shared/components/ui'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { cn } from 'src/shared/components/ui/utils'

import { EditReceipt } from './EditSubscription'
import { EmailTabsContent } from './EmailTabsContent'

export const Receipt = ({
  receipt,
}: {
  receipt: Receipt_ & {
    sender?: {
      tool: Tool_[] | null
    }
  }
}) => {
  return (
    <div className="p-4 h-[calc(100vh-10rem)] overflow-y-auto">
      <Tabs defaultValue="cost">
        <Row>
          <TabsList>
            <TabsTrigger value="cost">Cost info</TabsTrigger>
            {receipt.source === 'gmail' && (
              <TabsTrigger value="email">Email</TabsTrigger>
            )}
          </TabsList>
        </Row>

        <TabsContent value="cost">
          <div className={cn('mt-1 grid grid-cols-3 gap-3')}>
            {receipt.receipt_file && (
              <Image
                className="border rounded-2xl col-span-2 opacity-90"
                src={receipt.receipt_file || ''}
                alt="Receipt"
              />
            )}

            <EditReceipt receipt_id={receipt.id} />
          </div>
        </TabsContent>

        <TabsContent value="email">
          <EmailTabsContent receipt={receipt as any} />
        </TabsContent>
      </Tabs>
    </div>
  )
}
