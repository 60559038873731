import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { Column } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import { Calendar } from 'src/shared/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/shared/components/ui/popover'
import { cn } from 'src/shared/components/ui/utils'

export const DatePickerAccounts = ({
  date,
  setDate,
  placeholder,
}: {
  date: Date | undefined
  setDate: (date: Date | undefined) => void
  placeholder: string
}) => (
  <Column className="gap-1">
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="light"
          className={cn(
            'w-fit justify-start text-left',
            !date && 'text-muted-foreground'
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />

          {date ? format(date, 'yyyy/MM/dd') : <span>{placeholder}</span>}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(date) => setDate(date || new Date())}
          initialFocus
        />
      </PopoverContent>
    </Popover>

    <p className="text-xs ml-2">{format(date!, 'MMM do, yyyy')}</p>
  </Column>
)
