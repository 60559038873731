export const queryKeys = {
  org_user: 'org_user',
  vendorsSearch: 'vendorsSearch',
  tools: 'tools',
  receipts: 'receipts',
  org_users: 'org_users',
  vendors: 'vendors',
  vendor: 'vendor',
  organization: 'organization',
  organizations: 'organizations',
  org_id: 'org_id',
  user_activities: 'user_activities',
  user: 'user',
  overlapping_tools: 'overlapping_tools',
  userTools: 'userTools',
  toolsAll: 'toolsAll',
  emailAccount: 'emailAccount',
  emailAccounts: 'emailAccounts',
  scanEmailAccount: 'scanEmailAccount',
  tool: 'tool',
  org_vendor: 'org_vendor',
  senders: 'senders',
  notifications: 'notifications',
  createCheckoutSession: 'createCheckoutSession',
}
