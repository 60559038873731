import dayjs from 'dayjs'
import { Check, Loader } from 'lucide-react'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/shared/components/ui'
import { Card, ScrollArea } from 'src/shared/components/ui'

export const DataCard = ({
  data,
  cardTitle,
  description,
}: {
  data: any[]
  cardTitle: string
  description: string
}) => (
  <Card className="rounded-[20px] p-4 h-full">
    <Column className="mb-3">
      <Row className="items-center">
        <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse mr-2" />
        <p className="text-sm ">{cardTitle}</p>
      </Row>

      <p className="text-xs text-muted-foreground">{description}</p>
    </Column>

    {data
      ?.slice(0, 1)
      .map(({ id, created_at, tag, dataObject, dataArray, title }) => (
        <Column
          key={id}
          className="gap-2 bg-white/5 min-h-[80px] p-2 rounded-lg border"
        >
          <Row className="items-center gap-2" key={id}>
            {tag?.includes('finished') ? (
              <Check className="w-3 h-3 text-green-500" />
            ) : (
              <Loader className="w-3 h-3 animate-spin [animation-duration:2s]" />
            )}

            <p className="text-xs font-sm muted-foreground text-left mr-auto">
              {title}
            </p>

            <p className="text-muted-foreground">
              {dayjs(created_at).fromNow()}
            </p>
          </Row>

          {dataObject && (
            <Row className="p-2 rounded-lg gap-2 bg-black/10">
              <p className="text-xs text-muted-foreground">{dataObject}</p>
            </Row>
          )}
        </Column>
      ))}

    <ScrollArea className="h-[280px] mt-4">
      <Column className="gap-2 mr-4">
        {data
          ?.slice(1)
          .map(({ id, created_at, tag, dataObject, dataArray, title }) => (
            <Column key={id} className=" p-1 rounded-lg">
              <Accordion type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger
                    className="text-xs text-muted-foreground w-full"
                    disabled={!dataObject}
                    hideChevron={!dataObject}
                  >
                    <Row className="justify-between w-full" key={id}>
                      <p className="text-left text-xs">
                        {title.length > 30 ? title.slice(0, 30) + '...' : title}
                      </p>
                      <p className="text-xs text-muted-foreground">
                        {dayjs(created_at).fromNow()}
                      </p>
                    </Row>
                  </AccordionTrigger>

                  <AccordionContent>
                    <Column className=" p-1 rounded-lg w-fit">
                      <p className="text-xs text-muted-foreground">
                        {JSON.stringify(dataObject)}
                      </p>
                    </Column>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </Column>
          ))}
      </Column>
    </ScrollArea>
  </Card>
)
