import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { useOrgIdApi } from './apiOrgId'
import { queryKeys } from './queryKeys'

export type InsertReceipt = Database['public']['Tables']['receipt']['Insert']
export type UpdateReceipt = Database['public']['Tables']['receipt']['Update']
export type Receipt_ = Database['public']['Tables']['receipt']['Row']

export const useReceiptsApi = ({
  receipt_id,
  tool_id,
}: {
  receipt_id?: number
  tool_id?: string
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { orgId } = useOrgIdApi()
  /**
   * receiptsByTool info
   *
   * "!inner" says: if this table exists, return this and the parent.
   *
   * "tool!tool_sender_id_fkey" says: join parent with tool, and use the tool.sender_id connection.
   * There are two connections so we need to explicitly specify.
   */
  const { data: receiptsByTool } = useQuery({
    queryKey: [queryKeys.receipts, 'byTool', tool_id],
    queryFn: async () =>
      await supabase
        .from('receipt')
        .select('*, sender!inner(*, tool!tool_sender_id_fkey!inner(*))')
        .eq('sender.tool.id', tool_id!),
    enabled: !!tool_id,
  })

  const { data: receiptsByOrg } = useQuery({
    queryKey: [queryKeys.receipts, 'byOrg', orgId],
    queryFn: async () =>
      await supabase
        .from('receipt')
        .select('*, sender!inner(*, tool(*))')
        .eq('sender.organization_id', orgId!),
    enabled: !!orgId,
  })

  const { data: receiptsByOrgThisMonth } = useQuery({
    queryKey: [queryKeys.receipts, 'byOrgThisMonth', orgId],
    queryFn: async () =>
      await supabase
        .from('receipt')
        .select('*, sender!inner(*, tool(*))')
        .eq('sender.organization_id', orgId!)
        .gte('created_at', dayjs().startOf('month').toISOString())
        .lte('created_at', dayjs().endOf('month').toISOString()),
  })

  const { data: receiptById } = useQuery({
    queryKey: [queryKeys.receipts, receipt_id],
    queryFn: async () => {
      const res = await supabase
        .from('receipt')
        .select('*, sender(*)')
        .eq('id', receipt_id!)

      return res.data?.[0]
    },
    enabled: !!receipt_id,
  })

  const updateReceipt = useMutation({
    mutationFn: async (updateReceipt: UpdateReceipt) =>
      await supabase
        .from('receipt')
        .update(updateReceipt)
        .eq('id', updateReceipt.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })

      toast({
        variant: 'success',
        title: 'Updated receipt',
      })
    },
  })

  const addReceipt = useMutation({
    mutationFn: async (receipt: InsertReceipt) =>
      await supabase.from('receipt').insert(receipt),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })
    },
  })

  const deleteReceipt = useMutation({
    mutationFn: async ({ id }: { id: number }) =>
      await supabase.from('receipt').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.receipts] })

      toast({
        variant: 'success',
        title: 'Deleted receipt',
      })
    },
  })

  return {
    receiptById,
    receiptsByTool,
    receiptsByOrg,
    receiptsByOrgThisMonth,
    updateReceipt,
    deleteReceipt,
    addReceipt,
  }
}
