import * as React from 'react'
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/shared/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from 'src/shared/components/ui/chart'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'

import { useChartData } from './consts'

const chartConfig = {
  visitors: {
    label: 'Visitors',
  },
  cost: {
    label: 'Cost',
    color: 'hsl(var(--chart-6))',
  },
} satisfies ChartConfig

export const AreaChartComponent = () => {
  const [timeRange, setTimeRange] = React.useState('30d')

  const chartData = useChartData()

  const filteredData = chartData.filter((item) => {
    const date = new Date(item.date)
    const now = new Date()

    if (timeRange === '30d') {
      // Get first day of current month
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
      // Get last day of current month
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
      return date >= firstDay && date <= lastDay
    } else if (timeRange === '7d') {
      // Get first day of current week (Sunday)
      const firstDay = new Date(now)
      firstDay.setDate(now.getDate() - now.getDay())
      // Get last day of current week (Saturday)
      const lastDay = new Date(firstDay)
      lastDay.setDate(firstDay.getDate() + 6)
      return date >= firstDay && date <= lastDay
    } else {
      // 90d - show last 3 months
      // Get first day of current month minus 2 months
      const firstDay = new Date(now.getFullYear(), now.getMonth() - 2, 1)
      // Get last day of current month
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
      return date >= firstDay && date <= lastDay
    }
  })

  return (
    <Card>
      <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
        <div className="grid flex-1 gap-1 text-center sm:text-left">
          <CardTitle>Cost per day</CardTitle>

          <CardDescription>
            Showing total cost for the last {timeRange}
          </CardDescription>
        </div>

        <Select value={timeRange} onValueChange={setTimeRange}>
          <SelectTrigger
            className="w-[160px] rounded-lg sm:ml-auto"
            aria-label="Select a value"
          >
            <SelectValue placeholder="Last 3 months" />
          </SelectTrigger>

          <SelectContent className="rounded-xl">
            <SelectItem value="90d" className="rounded-lg">
              Last 3 months
            </SelectItem>

            <SelectItem value="30d" className="rounded-lg">
              This month
            </SelectItem>

            <SelectItem value="7d" className="rounded-lg">
              This week
            </SelectItem>
          </SelectContent>
        </Select>
      </CardHeader>

      <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <AreaChart data={filteredData}>
            <defs>
              <linearGradient id="fillCost" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="var(--color-cost)"
                  stopOpacity={0.8}
                />

                <stop
                  offset="95%"
                  stopColor="var(--color-cost)"
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>

            <CartesianGrid vertical={false} />

            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => {
                const date = new Date(value)
                return date.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                })
              }}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `$${value}`}
            />

            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  labelFormatter={(value) => {
                    return new Date(value).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                    })
                  }}
                  indicator="dot"
                />
              }
            />

            <Area
              dataKey="cost"
              type="bump"
              fill="url(#fillCost)"
              stroke="var(--color-cost)"
              stackId="a"
              label={{
                position: 'top',
                formatter: (value) =>
                  value > 0 ? `$${Math.round(value)}` : '',
                fontSize: 12,
                fill: 'var(--color-cost)',
              }}
            />

            <ChartLegend content={<ChartLegendContent />} />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
