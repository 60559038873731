import {
  // addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  isSameDay,
  isSameMonth,
  isToday,
  startOfMonth, // subMonths,
} from 'date-fns'
// import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { useState } from 'react'
import { useOrgIdApi } from 'src/api/db/apiOrgId'
import { useToolsApi } from 'src/api/db/apiTools'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { ScrollArea, Tooltip } from 'src/shared/components/ui'
// import { Button } from 'src/shared/components/ui/button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/shared/components/ui/card'
import { ScrollBar } from 'src/shared/components/ui/scroll-area'

export default function MonthCalendar() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId })

  const monthStart = startOfMonth(currentMonth)
  const monthEnd = endOfMonth(currentMonth)
  const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd })

  // const goToPreviousMonth = () => setCurrentMonth(subMonths(currentMonth, 1))
  // const goToNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1))

  const subscriptionEvents = tools?.tools?.map((tool) => ({
    date: tool.calculated?.nextRenewalDate
      ? new Date(tool.calculated?.nextRenewalDate)
      : null,
    toolName: tool?.name || '',
    toolLogo: tool?.org_vendor?.logo_url || '',
  }))

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 p-0">
        <CardTitle className="text-xl ">Subscription calendar</CardTitle>

        <Row c className="gap-3">
          <p className="text-sm  text-muted-foreground">
            {format(currentMonth, 'MMMM yyyy')}
          </p>

          {/* <div className="space-x-2">
            <Button variant="light" size="icon" onClick={goToPreviousMonth}>
              <ChevronLeftIcon className="h-4 w-4" />
              <span className="sr-only">Previous month</span>
            </Button>

            <Button variant="light" size="icon" onClick={goToNextMonth}>
              <ChevronRightIcon className="h-4 w-4" />
              <span className="sr-only">Next month</span>
            </Button>
          </div> */}
        </Row>
      </CardHeader>

      <CardContent className="p-0 pt-6">
        <div className="grid grid-cols-7 gap-2 text-center">
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
            <div key={day} className=" text-sm py-1 flex items-start">
              {day}
            </div>
          ))}

          {monthDays.map((day) => (
            <Column
              key={day.toString()}
              className={`p-3 text-sm rounded-xl border w-full h-20 flex items-start overflow-hidden ${
                !isSameMonth(day, currentMonth)
                  ? 'text-muted-foreground'
                  : isToday(day)
                  ? 'bg-muted '
                  : isSameDay(day, new Date())
                  ? 'bg-muted '
                  : ''
              }`}
            >
              <p className="text-xs text-muted-foreground">
                {format(day, 'd')}
              </p>

              <ScrollArea className="w-[65px]">
                <Row className="gap-1">
                  {subscriptionEvents
                    ?.filter((event) => {
                      return event.date ? isSameDay(event.date, day) : false
                    })
                    .map((event, i) => (
                      <Tooltip
                        key={i}
                        content={event.toolName}
                        delayDuration={0}
                      >
                        <div className="w-6 h-6">
                          <SaasIcon
                            toolName={event.toolName}
                            src={event.toolLogo || ''}
                            size="sm"
                          />
                        </div>
                      </Tooltip>
                    ))}
                </Row>

                <ScrollBar orientation="horizontal" className="opacity-0" />
              </ScrollArea>
            </Column>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
