import {
  ChartColumn,
  CreditCard,
  FlaskConical,
  LayoutDashboard,
} from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'

import { Costs } from '../Costs'
import { CostReduction } from './tabs/CostReduction'
import { Overview } from './tabs/Overview'

export const InsightsSubscriptionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab = searchParams.get('tab') || 'overview'

  const onTabChange = (value: string) => {
    setSearchParams({ tab: value })
  }

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-2">
        <FlaskConical />
        <h1 className="text-2xl ">Insights</h1>
      </Row>

      <Tabs value={currentTab} onValueChange={onTabChange}>
        <TabsList className="w-fit">
          <TabsTrigger value="overview">
            <LayoutDashboard className="w-4 h-4 mr-[5px]" />
            Overview
          </TabsTrigger>
          <TabsTrigger value="cost-reduction">
            <ChartColumn className="w-4 h-4 mr-[5px]" />
            Waste & Savings
          </TabsTrigger>
          <TabsTrigger value="cost">
            <CreditCard className="w-4 h-4 mr-[5px]" />
            Cost
          </TabsTrigger>
        </TabsList>

        <TabsContent value="overview">
          <Overview />
        </TabsContent>

        <TabsContent value="cost-reduction">
          <CostReduction />
        </TabsContent>

        <TabsContent value="cost">
          <Costs />
        </TabsContent>
      </Tabs>
    </Column>
  )
}
