import { Cable, FileStack, Mail, TableProperties } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'

import { Email } from './tabs/Email'

export const ReceiptsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab = searchParams.get('tab') || 'email'

  const onTabChange = (value: string) => {
    setSearchParams({ tab: value })
  }

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-2">
        <Cable className="w-4 h-4 mr-[5px]" />
        <h1 className="text-2xl ">Sources</h1>
      </Row>

      <Tabs value={currentTab} onValueChange={onTabChange}>
        <TabsList className="w-fit">
          <TabsTrigger value="email">
            <Mail className="w-4 h-4 mr-[5px]" />
            Email
          </TabsTrigger>

          <TabsTrigger value="pdf">
            <FileStack className="w-4 h-4 mr-[5px]" />
            PDFs
          </TabsTrigger>

          <TabsTrigger value="csv">
            <TableProperties className="w-4 h-4 mr-[5px]" />
            CSV
          </TabsTrigger>
        </TabsList>

        <TabsContent value="email">
          <Email />
        </TabsContent>
      </Tabs>
    </Column>
  )
}
